/* Container styling */
.crif-view-container {
    background: white;
    /* padding: 30px; */
    border-radius: 2px;
    width: 100%;
    text-align: center;
    position: relative;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); */
    margin: auto;
    opacity: 1;
}

/* Bottom padding */
.crif-bottom-padding {
    padding-bottom: 20px;
}

/* Table styling to match ANTD */
.crif-info-table {
    width: 100%;
    border: 1px solid #f0f0f0;
    border-collapse: collapse;
    background-color: #ffffff;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    color: #595959;
}

/* Table header styling */
.crif-table-header {
    background-color: green;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    border-bottom: 1px solid #d9d9d9;
}

/* Header cells */
.crif-info-table th {
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    font-weight: 500;
    color: #262626;
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
}

/* Body cells */
.crif-info-table td {
    color: #595959;
    border: 1px solid #f0f0f0;
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
}

/* Hover effect for rows */
.crif-info-table tbody tr:hover {
    background-color: #f5f5f5;
}

/* Card styling similar to ANTD */
.crif-card {
    /* border: 1px solid #d9d9d9; */
    border-radius: 2px;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

/* Download button styling */
.crif-download-button-container {
    margin-top: 20px;
    text-align: center;
}

.crif-download-button-container button {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    background-color: #1890ff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.crif-download-button-container button:hover {
    background-color: #40a9ff;
}

/* Hide download button in PDF */
@media print {
    .crif-download-button-container {
        display: none;
    }
}

.crif-preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    flex-direction: column;
    gap: 10px;
}

.crif-preloader span {
    font-size: 16px;
    color: #007bff;
    font-weight: bold;
}
.page-break {
    page-break-before: always; /* Forces a page break before the element */
  }
  
  .page-break-after {
    page-break-after: always; /* Forces a page break after the element */
  }
  
  .page-break-inside {
    page-break-inside: avoid; /* Prevents breaking inside the element */
  }
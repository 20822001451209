.remarkButton{
    padding:0px 20px;
    border-radius: 20px;
    border:2px solid #473391;
    color:#473391;
    background-color: white;
    font-size: 14px;
    font-weight: 500;
    height:40px;
    cursor: pointer;
}

.saveButton{
    padding:6px 30px;
    border:none;
    border-radius: 20px;
    background-color: #473391;
    color:white;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
}

.disabledButton{
    background:transparent;
    background-color: #cccccc;
    color:#473391;
}

.textArea{
    border:1px solid #CCCCCC;
    padding:10px;
}

.inputContainer,.selectContainer{
    border:1px solid #cccccc;
    padding:4px 12px;
    width:100%;
    height:40px;
    border-radius: 8px;
    font-size: 12px;
}


.followupContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.followup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 600px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.followupSection {
    display: flex;
    flex-direction: column;
}

.followupSection p {
    margin: 0 0 10px;
    font-weight: bold;
}

.followupSection ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.followupSection ul li {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}

.action {
    display: flex;
    gap: 15px;
    margin-top: 5px;
}

.action a {
    font-size: 12px;
    color: #5d3fd3;
    text-decoration: none;
    cursor: pointer;
}

.divider {
    height: 40px;
    width: 1px;
    background-color: #e0e0e0;
    margin: 10px 20px;
}

.divider+.followupSection ul li{
    margin-top: 30px;
}
.slider-container {
    /* position: relative; */
    width: 100%;
    overflow: hidden;
}

.slider-content {
    display: flex;
    transition: transform 0.5s ease;
}

.card {
    min-width: 100%;
    box-sizing: border-box;
}

.card-content {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-content h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
}

.card-content p {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.card-content button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.card-content button:hover {
    background: #0056b3;
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    color: #000;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    font-size: 12px;
    border-radius: 50%;
}

.slider-button.left {
    left: 5px;
}

.slider-button.right {
    right: 5px;
}

.bre-dashboard-container {
    background-color: #f4f6fa;
    padding: 40px 30px;
    border-radius: 12px;
    min-height: 100vh;
  }
  
  .bre-dashboard-header {
    text-align: left;
    margin-bottom: 40px;
  }
  
  .bre-dashboard-header h1 {
    font-size: 30px;
    font-weight: 700;
    color: #2b2e4a;
    margin-bottom: 8px;
  }
  
  .bre-dashboard-welcome {
    font-size: 18px;
    color: #6c757d;
    font-weight: 400;
  }
  
  .bre-tabs-nav .nav-link {
    background: #fff;
    border: 1px solid #dcdcdc;
    color: #4a4a4a;
    border-radius: 30px;
    padding: 10px 24px;
    margin-right: 15px;
    font-weight: 500;
    transition: all 0.25s ease-in-out;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  }
  
  .bre-tabs-nav .nav-link:hover {
    background-color: #f0f0f5;
    border-color: #bbb;
  }
  
  .bre-tabs-nav .nav-link.active {
    background-color: #4b3dde;
    color: #fff;
    border-color: #4b3dde;
    box-shadow: 0 4px 10px rgba(75, 61, 222, 0.2);
  }
  
  .bre-tabs-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    animation: fadeIn 0.4s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(6px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
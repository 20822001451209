.card {
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
  position: relative;
}
.card .card-heading {
  padding: 25px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  position: relative;
}
.card .card-heading .card-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}
.card .card-body {
  padding: 15px;
}
.card .card-footer {
  padding: 15px;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.card.card-success {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.card.card-success .card-heading {
  background: #31b93e;
  color: #ffffff;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}
.card.card-danger {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.card.card-danger .card-heading {
  background: #e24141;
  color: #ffffff;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}
.card.card-secondary {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
.card.card-secondary .card-heading {
  background: rgba(0, 0, 0, 0.12);
  color: #ffffff;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
  border-radius: 5px 5px 0px 0px;
}

.search-wrap {
  padding: 15px;
  position: relative;
  margin-bottom: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
}
.search-wrap .row {
  margin-right: -5px;
  margin-left: -5px;
}
.search-wrap .row .col-sm-6,
.search-wrap .row .col-md-2,
.search-wrap .row .col-xs-6,
.search-wrap .row .col-xs-12 {
  padding-right: 5px;
  padding-left: 5px;
}
.search-wrap label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
}
.search-wrap .css-1okebmr-indicatorSeparator {
  display: none;
}
.search-wrap .css-yk16xz-control,
.search-wrap .css-1pahdxg-control {
  height: 42px;
  border-radius: 10px !important;
}
.search-wrap .css-1wa3eu0-placeholder {
  font-size: 12px;
}
.css-1wa3eu0-placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}
.search-wrap .css-tlfecz-indicatorContainer,
.search-wrap .css-1gtu0rj-indicatorContainer {
  padding: 8px 4px;
}
.search-wrap .advancesearch {
  font-size: 12px;
  position: absolute;
  left: 50%;
  bottom: -23px;
  background: #ffffff;
  -webkit-border-radius: 0 0 100px 100px;
  -moz-border-radius: 0 0 100px 100px;
  -ms-border-radius: 0 0 100px 100px;
  -o-border-radius: 0 0 100px 100px;
  border-radius: 0 0 100px 100px;
  margin-left: -120px;
  padding: 2px 15px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.search-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.search-flex .searchitems {
  width: 100%;
  padding: 0px 5px;
}

@media (min-width: 768px) {
  .search-wrap {
    padding-bottom: 10px;
  }

  .search-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .search-flex .searchitems {
    width: 20%;
  }
}
@media (min-width: 992px) {
  .search-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .search-flex .searchitems {
    width: 14.285;
  }
}

/* container-fluid Start ======================= */
.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
/* .nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after, */
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after,
header:before,
header:after,
footer:before,
footer:after {
  display: table;
  content: " ";
}

.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
/* .nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after, */
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after,
header:after,
footer:after {
  clear: both;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}
.rejectbtn {
  border: none;
  color: rgb(252, 103, 103);
  font-size: 12px;
  padding: 15px;
  background: #fff;
  float: right !important;
  cursor: pointer;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

/*Common Classes*/
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* container-fluid End ================ */

/* search-wrap Start ===================== */
@media (max-width: 767px) {
  .search-wrap {
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 999;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 0;
    border: 0;
  }
  .search-wrap .btn.btn-primary {
    width: calc(50%);
  }
  .search-wrap .btn.btn-default {
    width: calc(50% - 10px);
  }
  .search-wrap .advancesearch {
    position: initial;
    margin-top: 15px;
  }

  .search-wrap:before {
    content: "";
    width: 24px;
    height: 24px;
    font-family: "dc" !important;
    z-index: 1000;
    font-size: 24px;
  }

  .manageStock {
    padding: 0px;
  }
  .manageStock h1 {
    font-size: 24px;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 5px;
  }
  .manageStock .card {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }
  .manageStock .card .card-body {
    background: #f1f3f6;
  }
  .manageStock .card .card-body .car-list > li {
    background: #ffffff;
    margin-bottom: 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  }
  .manageStock .card .card-body .car-list > li .wrap {
    padding: 0px;
  }
  .manageStock .card .card-body .car-list > li .wrap .col-sm-10 > .row {
    padding: 15px;
  }
  .manageStock .card .card-body .car-list > li .actionbtn {
    display: block;
  }
  .manageStock .card .card-body .car-list .lead-circle {
    margin-bottom: 15px;
  }
}

@media (min-width: 768px) {
  .search-wrap {
    padding-bottom: 10px;
  }

  .search-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .search-flex .searchitems {
    width: 20%;
  }
}

.search-wrap {
  padding: 15px;
  position: relative;
  margin-bottom: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  background: #ffffff;
}
.search-wrap .row {
  margin-right: -5px;
  margin-left: -5px;
}
.search-wrap .row .col-sm-6,
.search-wrap .row .col-md-2,
.search-wrap .row .col-xs-6,
.search-wrap .row .col-xs-12 {
  padding-right: 5px;
  padding-left: 5px;
}
.search-wrap label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
}
.search-wrap .css-1okebmr-indicatorSeparator {
  display: none;
}
.search-wrap .css-yk16xz-control,
.search-wrap .css-1pahdxg-control {
  height: 42px;
  border-radius: 10px !important;
}
.search-wrap .css-1wa3eu0-placeholder {
  font-size: 12px;
}
.css-1wa3eu0-placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}
.search-wrap .css-tlfecz-indicatorContainer,
.search-wrap .css-1gtu0rj-indicatorContainer {
  padding: 8px 4px;
}
.search-wrap .advancesearch {
  font-size: 12px;
  position: absolute;
  left: 50%;
  bottom: -23px;
  background: #ffffff;
  -webkit-border-radius: 0 0 100px 100px;
  -moz-border-radius: 0 0 100px 100px;
  -ms-border-radius: 0 0 100px 100px;
  -o-border-radius: 0 0 100px 100px;
  border-radius: 0 0 100px 100px;
  margin-left: -120px;
  padding: 2px 15px;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2);
  opacity: 1;
}
/* search-wrap End ===================== */

/* partner new design start=================== */

.partnerflex {
  display: flex;
  justify-content: space-around;
}
.partnertype {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.partnername {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  span {
    font-size: 12px;
    font-weight: 400;
  }
}
.commontype {
  font-size: 12px;
  margin-bottom: 10px;
}
.commonpartner {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
}
.flexcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.basiccard {
  display: flex;
  align-items: center;
}
.basiccheckbox {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  label {
    margin-bottom: 0px !important;
    margin-left: 8px;
  }
}
.createddate {
  font-size: 12px;
  margin-left: 40px;
}
.common-details {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.approvalflex {
  display: flex;
  align-items: center;
  margin: 20px 0px;
}

.approvalflexx {
  display: flex;
  align-items: center;
  margin: 61px 0px;
}
.approvalpartner {
  font-size: 14px;
  width: 20%;
  font-weight: 600;
  margin-right: 50px;
}

.approvalpartnerr {
  font-size: 14px;
  width: 14%;
  font-weight: 600;
  margin-right: 40px;
  margin-left: 21px;
}

.approvalpartneer {
  font-size: 14px;
  width: 7%;
  font-weight: 600;
  margin-right: 25px;
}

.progress-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}
.stepsflex {
  display: flex;
  align-items: center;
}
.steppagecount {
  display: inline-block;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 23px;
  background: #00bdd6ff;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.step a.active .steppagecount {
  background-color: #6147ff;
  color: white;
}
.step {
  width: 20%;
  text-align: center;
  position: relative;
}
.step:last-child {
  width: 5%;
}
.step-text {
  margin-top: 10px;
}
.step-line {
  height: 4px;
  background-color: #ccc;
  position: absolute;
  left: 50%;
  transform: translateX(-15%);
  width: 100%;
}
.step.active .step-line {
  background-color: #007bff;
}

/* partner new design END==========  */

.pagepartner {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-evenly;
}
.pagepartner li {
  width: 23%;
  display: inline-block;
}
.pagepartner li a.active {
  color: #6147ff;
}
.pagepartner li a {
  color: #000;
}
.pagepartner li:last-child {
  width: 8%;
}

/* button  Start */

.btn {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  /* text-transform: uppercase; */
  font-family: 'Poppins', sans-serif;
  height: 42px;
  font-weight: 500;
  font-size: 16px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-new {
 
  padding: 2px 10px;
  background: #1AC18F;
  color: #FFF;
  border: 1px solid #1AC18F;
  border-radius: 10px;
}
.btn {
  display: inline-block;
  /* font-weight: bold; */
  /* color: #6147FF; */
  text-align: center;
  align-items: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 12px;
  /* padding: 8px 15px; */
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/* .btn.btn-default {
  background: #ffffff;
  border: solid 1px #6147FF;
  color: rgba(0, 0, 0, 0.54);
  padding-top: 11px;
}
.btn.btn-default:hover, .btn.btn-default:focus {
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.4);
  border: 1px solid #6147FF;
  background-color: #6147FF;
  color: white;
} */
.btn.btn-reset {
  background: white;
  border: 1px solid lightgray;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
}
/* .btn.btn-reset-new {
  background: white;
  border: 1px solid lightgray;
  color: black;
  padding: 3px 15px;
  cursor: pointer;
} */
.btn.btn-reset-new:hover, .btn.btn-reset:focus {
  /* box-shadow: none;
  border: none; */
  color: white;
  background-color: #6147FF;
}
.btn.btn-reset:hover, .btn.btn-reset:focus {
  /* box-shadow: none;
  border: none; */
  color: white;
  background-color: #6147FF;
}
.btn.btn-primary {
  background: #6147FF;
  color: #ffffff;
  display: flex;
  justify-content: center;
}



.mrg-r10 {
  margin-right: 10px;
}
/* .commoniconbtn{
  display: flex;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 4px;
  cursor: pointer;
} */

.pull-right {
  float: right !important;
}


/*Common Classes*/
.text-left {
  text-align: left;
}

.text-left {
  text-align: left;
}





.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.hinge {
  animation-duration: 2s;
}


@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDownBig {
  animation-name: fadeInDownBig;
}


.loading {
  position: relative;
  pointer-events: none;
}
.loading:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.54);
  z-index: 997;
}
.loading:after {
  content: "";
  position: absolute;
  top: 20%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  border: 8px solid rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  border-top: 8px solid #6147FF;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  z-index: 998;
}

.flexcard{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.basiccard{
  display: flex;
  align-items: center;
}

.createddate{
  font-size: 12px;
  margin-left: 40px;
}


.common-details{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}


.pandetailsbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #434150;
  height: 38px;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}


.rc-inline .gs_control, .rc-inline .form-field {
  display: inline-block;
  margin-right: 30px;
}

.partnerunsubscribed{
  background:#1AC18F;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  padding: 5px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

}



/* Kyc CSS Start =================== */

.f12 {
  font-size: 12px;
}

.sidebar-box{
  display: flex;
}

.sidebar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid lightgray;
  background: #fff;
  width: calc(20% - 10px);
  padding: 20px;
  margin-right: 10px;
}
.sidebar .tab {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 10px;
  background: #f1f1f1;
  transition: background-color 0.3s;
}
.sidebar .tab:hover {
  background-color: rgb(155, 155, 248);
  color: #fff;
}
.sidebar .active {
  background-color: rgb(155, 155, 248);
  color: white;
}



.tab-list {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.tab-list .nav-link {
  border-bottom:
    2px solid transparent;
  padding-bottom:
    8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, .87);
  margin-right: 30px;
}

.tab-list .nav-link.active {
  font-weight: 600;
  color: rgba(11, 189, 221, .87);
  /* border-bottom:
    2px solid #0bbddd; */
} 
.tab-list-new .nav-link {
  position: relative;
  display: inline-block;
  align-items: center;
  padding: 15px 20px 8px;
  color: inherit;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 1px;
  z-index: 1;
}

.tab-list-new .nav-link::after {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -1;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  background: #EAEAFC;
  color: #000;
  transform: perspective(5px) rotateX(1deg);
  transform-origin: left;  
  border: 1px solid rgb(223, 223, 223);
  
}

.tab-list-new .nav-link.active::after {
  transform: perspective(5px) rotateX(1deg);
  transform-origin: left;
  background: transparent; 
  color: #000;
  border: 1px solid rgb(223, 223, 223);
}


.content{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid lightgray;
  background: #fff;
  width: calc(80% - 10px);
  padding: 20px 30px;
  margin-right: 10px;
}
/* 
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
} */

/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.form-group {
  /* width: 100%; */
  margin-bottom: 20px;
  position: relative;
}

.hide {
  display: none !important;
}

/* 

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.67);
  background-color: #ffffff;
}
.table th,
.table tr{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.table td {
  padding: 16px 10px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.table tbody + tbody {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.table-sm th,
.table-sm td {
  padding: 5px;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px;
  padding: 10px;
  background: #fff1d6;
  font-weight: 600;
  text-align: left;
}
.payouttable{
  background: #fff !important;
}
.payout-row{
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.table-hover tbody tr:hover td {
  background-color: rgba(33, 118, 174, 0.04);
}

.table-responsive {
  display: block;
  width: 100%;
}
.fontsize12px  th,
.fontsize12px  td
{
 font-size: 12px !important;
}

.table-responsive > .table-bordered {
  border: 0;
} */

/* Kyc CSS END =================== */

.comment-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.comment-view label {
  align-self: flex-start;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.comment-view textarea {
  width: 100%;
  max-width: 600px;
  min-height: 150px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: vertical;
}

.comment-view span {
  color: red;
}

.form-field {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  .form-control {
      display: block;
      width: 100%;
      height: 38px;
      padding: 5px 10px;
      font-size: 13px;
      font-family: poppins;
      line-height: 1.428571429;
      color: rgba(0, 0, 0, 0.87);
      background-color: #FFFFFF;
      background-image: none;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      outline: none;
  }
}


/* Animation Start */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUpBig {
  animation-name: fadeInUpBig;
}





.kyc-heading{
  display: flex;
  width: 100%;
  font-size: x-large;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Poppins', sans-serif;
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
  margin-bottom: 20px;
}
.kyc-document {
  display: flex;
  /* width: 500px; */
  overflow: hidden;
  overflow-y: auto;
}

.col-sm-6 {
  width: 50%;
}

.modal-popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modal-context{
  background: #fff;
  width: 600px;
  padding: 70px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  
}

.close{
  position: absolute;
  top: 10px;
  right:10px;
  font-size: 30px;
  cursor: pointer;
}



.image-preview-container .pfd-img-height > div > div {
  display: flex;
  top: inherit !important;
  bottom: 0px;
  background: #fff !important;
  box-shadow: none !important;
  border: 0px !important;
  right: 0px !important;
  width: 100%;
  padding: 10px 0px;
  align-items: center;
  justify-content: center;
}

.image-preview-container .pfd-img-height > div > div > div {
  border-radius: 100%;
  background: #fff;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 5px;
  border: 1px solid rgb(204, 204, 204);
}

.image-preview-container .pfd-img-height > div > div > div svg {
  height: 18px !important;
  width: 18px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.image-preview-container .pfd-img-height > div > div > div svg path,
.image-preview-container .pfd-img-height > div > div > div svg polygon {
  stroke: #000;
}

.image-preview-container .pfd-img-height .pan-container > div {
  height: auto !important;
  width: auto !important;
  margin: 0px !important;
}

.img-bx-select-opt {
  position: relative;
  cursor: pointer;
  /* margin: 10px 0px; */
  overflow: hidden;
  /* .image-bx {
      height: 40px;
  } */
  &:hover {
      .close-icn {
          opacity: 1;
      }
  }
  .custom-control-label {
      display: flex;
  }
}




.ic-word-file:before {
  content: "";
}

.ic-pdf-file:before {
  content: "";
}

.icons-normal {
  font-size: 16px;
}

.verifiedkyc{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E0FAED;
  background: #E0FAED;
  height: 42px;
  border-radius: 10px;
  padding: 5px 20px;
  margin-top: 20px;
  width: 450px;
}
.notverifiedkyc{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FBF8E7;
  background: #FBF8E7;
  height: 42px;
  color: #D2A340;
  border-radius: 10px;
  padding: 5px 20px;
  margin-top: 50px;
  width: 450px;
}
.kycverification{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  background: #6147FF;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}
.kycverification[disabled] {
  background-color: #919191;
  color: #fff;
  cursor: not-allowed;
}
.kycaddress{
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  padding: 10px 0px;
  margin-bottom: 10px;
}



.progress-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  /* background: #000; */
  /* width: 100%;
  position: absolute; */
}
.stepsflex{
  display: flex;
  align-items: center;
}
.steppagecount{
  display: inline-block;
  align-items: center;
  width:24px;
  height: 24px;
  border-radius:50%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 23px;
  background: #00BDD6FF;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.step a.active .steppagecount {
background-color: #6147FF;
color: white;
}
.step {
  width: 20%;
  text-align: center;
  position: relative;
}
.step:last-child {
  width: 5%;
}
.step-text {
  margin-top: 10px;
}
.step-line {
  height: 4px;
  background-color: #ccc;
  position: absolute;
  left: 50%;
  transform: translateX(-15%);
  width: 100%;
}
.step.active .step-line {
  background-color: #007bff;
}
















.more-file-upload {
  display: flex;

  p {
      display: block;
      text-decoration: underline;
      font-size: 14px;
      line-height: 30px;
      color: #000;
      margin-left: 10px;
      margin-bottom: 0px !important;
      text-align: center;
  }
  input[type="file"] {
      opacity: 0;
      width: 5%;
      outline: 0;
      z-index: 1;
      position: absolute;
      /* top: -20px; */
      cursor: pointer !important;
      left: 0;
  }
}

/* Lead Import Screen Css Start */


.leadcard{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  span{
    color: red;
  }
}
.leadcardbody{
  width: 100%;
  max-width: 1000px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}
.leadfileinput{
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.leadfileflex{
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.leadfileupload{
  margin-left: 50px;
}
.leaddownloadfile{
  color: #6147FF;
  cursor: pointer;
}
.leadrequired{
  margin-bottom: 10px;
}
.leadnote{
  margin: 50px 0px;
  span{
    color: #000;
    font-weight: 600;
  }
}
.leaduploadbtn{
  height: 42px;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background: #6147FF;
  color: #fff;
  cursor: pointer;
}
.leaderror {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 50px 0px; */
  span{
    color: red;
  }
}
.noaccesspage{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 500px;
}
.noaccessheading{
  margin: 50px 0px 20px 0px;
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
.noaccesspara{
  font-size: 16px;
  margin-bottom: 10px;
}


/* *********************************** Support Partner Listing *************************************/

.supportcount{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #E6E3FF;
  border-radius: 6px;
  padding: 10px;
  width: 30%;
}
.supportinfo{
  display: flex;
}
.supportcountinfo{
  display: flex;
  gap: 20px;
  padding: 0px 10px;
  width: 30%;
  border-right: 1px solid #ddd;
}
.supportlisting{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  li{
    font-size: 12px;
    font-weight: 600;
    width: 28%;
    text-align: center;
    cursor: pointer;
  }
}
.supportheading{
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
.supportheadingnew{
  width: 22%;
  font-weight: 600;
  color: #000;
}
.supportfollowup{
  text-align: center;
  /* width: 20%; */
  border-right: 1px solid #ddd;
  padding: 0px 10px;
}
.supportfollowupnew{
  text-align: center;
  width: 30%;
  border-right: 1px solid #ddd;
  padding: 0px 10px;
}
.supporttickets{
  text-align: center;
  width: 12%;
  border-right: 1px solid #ddd;
  font-weight: 600;
  font-size: 12px;
  padding: 0px 10px;
}
.supportstatus{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  width: 38%;
}
.supportlistingstatus{
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  padding: 0px 10px;
  gap: 30px;
}
.supportbtnflex{
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.exportdumpbtn{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}
.supportapplybtn{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5D48F5;
  border-radius: 30px;
  height: 36px;
  font-size: 12px;
  padding: 10px 20px;
  border: none;
  color: #fff;
  cursor: pointer;
}
.supportresetbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 30px;
  font-size: 12px;
  height: 36px;
  padding: 10px 20px;
  border: 1px solid #BCC1CA;
  color: #000;
  cursor: pointer;
}
.filterflex .css-13cymwt-control  {
  background: #dbdbdb !important;
  width: 150px;
}


.support-tabs {
  display: flex;
  gap:10px
}

.tablinks {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border: none;
  outline: none;
  border-radius: 30px;
  cursor: pointer;
  padding: 10px 20px;
  height: 42px;
  transition: 0.3s;
}

.tablinks.active {
  background-color: #5D48F5;
  color: #fff;
}

.tabcontent {
  padding: 16px;
  border: 1px solid #ccc;
  margin-top: 10px;
}
.statusflexlist{
  display: flex;
  gap: 5px;
  align-items: center;
}
.supportliststatus{
  background: #E05858;
  padding: 2px 5px;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
}
.supporttime{
  /* color: #E05858; */
  margin-top: 5px;
}
.statusinactive{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffffe8;
  padding: 2px 5px;
  border-radius: 5px;
  max-width: 100px;
}
.subpartnerdeatils{
  font-size: 12px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  span{
    color: #5D48F5;
    margin-left: 5px; 
    cursor: pointer;
  }
}
.subpatnerlogin{
  display: flex;
  align-self: center;
  gap: 20px;
  font-size: 12px
}
.tabsflex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tabsbetween{
  display: flex;
  gap: 10px;
}

.count-active {
  color: #E05858;
  font-weight: bold;
}

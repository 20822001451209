.apf-edit-container {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
  }
  
  .apf-edit-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group input[disabled],
  .form-group select[disabled] {
    background-color: #f5f5f5;
  }
  
  .form-row {
    display: flex;
    gap: 15px;
    /* margin-bottom: 15px; */
  }
  
  .form-row .form-group {
    flex: 1;
  }
  
  .lender-search {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    /* margin-bottom: 50px; */
  }
  
  .lender-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .lender-list li {
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .lender-list li:last-child {
    border-bottom: none;
  }

  .button-container {
    display: flex;
    /* justify-content: space-between; */
    margin-top: 95px;
  }
  
  .close-button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .save-button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .save-button:hover {
    background-color: #ff0000;
  }
  
.multi-select {
    width: 100%;
}

.select__control {
    padding: 8px;
    margin-bottom: 10px;
}

.select__menu {
    z-index: 100;
}

/* /APF Add Buttton Component */

.Add-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 10px;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  font-size: 18px;
  color: #fff;
  background-color: #6147FF;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* text-transform: uppercase; */
  letter-spacing: 0.5px;
}

.add-button .add-icon {
  font-size: 16px;
  margin-right: 8px;
  font-weight: bold;
}

.add-button:hover {
  background-color: #214088;
}

.add-button:active {
  background-color: #121212;
}
.edit-role-outer {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.edit-role-main {
  font-family: "Poppins", sans-serif;
  background: $white;
  border-radius: 25px 0 25px 0;
  width: 520px;
  height: 100%;
  padding: 40px;
  padding-bottom: 100px;
  position: absolute;
  right: 0;
  overflow-y: auto;
  border: 0px;
  fieldset {
    padding: 0px;
    border: 0px;
    &:focus-visible {
      border: 1px solid rgba(0, 0, 0, 0.6);
      border-radius: 8px;
    }
  }
  .role-status {
    display: flex;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 20px;
    .material {
      width: 280px;
    }
    input {
      margin-bottom: 0px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: $heading-color;
    font-family: "Poppins", sans-serif;
  }
}
.on-off-toggle {
  margin-left: 20px;
}

.assign-user-feature-tabs {
  margin-top: 15px;
  .tab-data {
    margin-top: 20px;
  }
}

.user-list {
  margin: 20px 0;
  ul {
    display: flex;
    white-space: normal;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 450px;
    li {
      width: calc(50% - 20px);
      display: flex;
      padding: 10px 0;
      height: 46px;
    }
  }
  &.assign-featue-list {
    li {
      width: 100%;
      padding: 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .feature-type-header {
      font-size: 16px;
      font-weight: 600;
      color: black;
      margin: 10px 0;
    }
  }
  .custom-control-label::before {
    top: 2px;
  }
  .custom-control-label::after {
    top: 2px;
  }
}
.user-permission {
  display: none;
}

.custom-checkbox .custom-control-input:checked ~ .user-permission {
  display: flex;
}

a.close-icn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  color: #000;
  cursor: pointer;
}
.role-name-status {
  .toggleApp {
    // width: 350px;
    margin: auto;
    margin-left: 10px;
  }

  .ToggleSwitch {
    background-color: rgba(171, 171, 171, 0.2);
    height: 24px;
    width: 56px;
    border-radius: 1em;
    &.active {
      background-color: rgba(11, 189, 221, 0.2);
    }
  }
  .ToggleSwitch .knob {
    position: relative;
    width: 22px;
    height: 22px;
    background: rgba(171, 171, 171, 1);
    border-radius: 50%;
    left: 0em;
    transition: left 0.3s ease-out;
    top: 1px;
    &.active {
      background: $accent-color;
      &::after {
        content: "";
        background: red;
      }
    }
  }
  .ToggleSwitch .knob.active {
    left: 34px;
  }
  .toggle-status {
    margin-left: 66px;
    margin-top: -20px;
    font-size: 13px;
    font-weight: 500;
  }
  .knob ~ .toggle-status {
    color: #e02020;
  }
  .knob.active ~ .toggle-status {
    color: #000;
  }
}

.mg80{
  margin-top: 80px;
}
.topcontainercard {
    padding: 16px;
    background: linear-gradient(97.02deg, #ece7fe 0%, #eafaf3 102.86%);
  }
  .topcontainer {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 20px;
    background-color: rgb(254, 254, 254);
    display: flex;
    border-radius: 10px;
    border: 1px solid rgb(199, 199, 199);
  }
  .uploaddocs {
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 48px;
    align-items: center;
    border-radius: 10px;
    text-align: center;
    width: 33.3%;
  }
  .uploaddocs button {
    cursor: pointer;
    background-color: transparent;
    border: 0;
  }
  .uploaddocs.active button {
    color: white;
  }
  .uploaddocs.active {
    background-color: rgba(97, 71, 255, 1);
    color: white;
  }
  .uploadbox {
    margin-top: 20px;
  }
  .partnername {
    color: #000;
    font-weight: 600;
  }
  .uploadpara {
    color: #000;
    font-size: 13px;
    margin: 10px 0px;
  }
  .uploadheading {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
  }
  .selectbox {
    margin-bottom: 20px;
  }
  .submitbtncenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .docsubmitbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6147ff;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    margin-top: 50px;
    width: 100%;
    height: 42px;
  }
  .docnewaccordion {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
  }
  .docnewaccordion-item {
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .docnewaccordionborder {
    background: #fff;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #ccc;
  }
  .docitemicon {
    display: flex;
    align-items: center;
  }
  .docnewaccordion-header {
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 24px;
    }
  }
  .docnewaccordion-content {
    // padding: 10px;
    border-top: 1px solid lightgray;
    display: none;
  }
  .iconstyle {
    font-size: 24px;
    color: #6147ff;
    cursor: pointer;
  }
  .doclist {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid lightgray;
      padding: 10px;
    }
  }
  .doclist {
    li:last-child {
      border-bottom: none;
    }
  }
  .docnewaccordion-item.open .docnewaccordion-content {
    display: block;
  }
            // Loan Details card css start  //
  
  .docloandetailscard{
    margin-bottom: 20px;
    background: #fff;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 20px 0px 10px 0px;
  }
  .dcoimgcenter{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border-bottom: 1px solid lightgray;
  }
  .docloanname{
    margin: 10px 0px;
    font-size: 15px;
    font-weight: 600;
    color: #000;
  }
  .docloancommon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: 10px 16px;
  }
  .docloanprice{
    font-size: 13px;
    font-weight: 600;
    color: #000;
  }
  .loandetailsicon{
    display: flex;
    align-items: center;
    img{
      margin: 5px 0px 0px 10px;
      cursor: pointer;
    }
  }
  .statusdetails{
    margin-left: 46px;
    font-size: 11px;
    color: #616060;
  }
  
  
  
  
  
  
  @media(min-width:992px){
    .pcdoccard{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .pcdocbox{
      margin-top: 50px;
      border: 1px solid lightgray;
      padding: 16px;
      width: 100%;
      background: #fff;
      text-align: center;
      max-width: 400px;
      border-radius: 10px;
    }
    .loandetailscardpc{
      margin-top: 50px;
      width: 100%;
      max-width: 400px;
    }
  }
  
  .file-upload-sanction {
    display: flex;
    position: relative;
    overflow: hidden;
    justify-content: space-between;
    // cursor: pointer;
    background: #E8ECFA;
    color: black;
    padding: 10px 20px;
    font-size: 12px;
    border-radius: 20px;
    border: none;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  
  .file-upload-sanction input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    // width: 100%;
    height: 100%;
    cursor: pointer;
  }
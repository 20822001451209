.whatsapp-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 111000;
  }
  
  .whatsapp-icon-wrapper {
    position: relative;
    cursor: pointer;
  }
  
  .whatsapp-icon {
    width: 30px; /* Adjust based on your image size */
    height: 30px; /* Adjust based on your image size */
  }

  .mail-container {
    position: fixed;
    bottom: 70px;
    right: 10px;
    z-index: 111000;
  }
  
  .mail-icon-wrapper {
    position: relative;
    cursor: pointer;
  }
  
  .mail-icon {
    width: 40px; /* Adjust based on your image size */
    height: 40px; /* Adjust based on your image size */
  }
  
  
  
  .new-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    padding: 1px 3px;
    border-radius: 50%;
    font-size: 10px;
  }


  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .new-badge-new {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    padding: 2px 5px;
    border-radius: 10%;
    font-size: 12px;
    width: 100px;
    animation: blink 2s infinite; /* Apply the blinking animation */
  }
  
  .message-list {
    position: absolute;
    bottom: 60px; /* Adjusted to appear above the icon */
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px;
    margin: 0;
    max-height: 300px;
    max-width: 250px;
    min-width: 200px;
    width: auto;
    overflow-y: auto;
    z-index: 110000; /* Ensure the message list has a higher z-index */
  }
  
  .message-list li {
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .message-list li:last-child {
    border-bottom: none;
  }

.incoming-call-container {
  position: fixed;
  bottom: 140px;
  right: 14px;
  z-index: 110000;
}

.incoming-call-icon {
  width: 35px;
  height: 35px;
}
  
$accent-color: #473391;
$promotional-color: #8c8c8c;
$promotional-color-2: #e02020;
$highlight-color: #30b38a;
$primary-color: #2a2a2a;
$body-color: #ffffff;
$text-color:#000;
//$text-color: rgba(#000, 0.6);
$placeholder-color: rgba($text-color, 0.5);
$overlay-color: rgba(#24272c, 0.4);
$border-color: rgba(#000, 0.06);
$heading-color: #000;
$white: #fff;
$color-valid: #30b38a;
$color-invalid: #f56b75;
$table-bg: #e3e7f1;
$hint-color: #80818b;
$input-inactive-bg-color: #f5f5f6;
$checkbox-color: #6147FF;
$checkbox-border-color: #80bdff;
$checkbox-shadow-color: rgb(0, 123, 255);
$btncolor:#9b9bf8;
$lightpink:#fff6f6;
$input:#cccccc;
$backgroundcolor:#C6F1FF;

$spacing-xxl: 40px;
$spacing-xl: 24px;
$spacing-lg: 20px;
$spacing-md: 16px;
$spacing-sm: 8px;
$spacing-xs: 4px;
/*===================main nav===================*/
$container: 1306px;


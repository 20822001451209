.stepcard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    margin: 0px 0px 20px 20px;

}
.arrow-container {
  display: flex;
  align-items: center;
  width: 85%;
}

.hexagon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -25px;
  font-size: 12px;
  width: 20%;
  height: 28px;
  // background-color: #9492e9;
  background-color: #e8ecfa;
  color: #000;
  clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 14% 50%);
  // &:nth-child(1) {
    // margin-left: 0px;
    // color: #fff;
    // background-color: #9492e9;
    // clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 0% 50%);
    // }
    // &:nth-child(2) {
      //   color: #fff;
      //   background-color: #9492e9;
      // }
      // &:nth-child(3) {
        //   color: #000;
        //   background-color: #e8ecfa;
        // }
        // &:nth-child(4) {
          // color: #000;
          // background-color: #e8ecfa;
          // clip-path: polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%, 14% 50%);
          // }
          // &:nth-child(5) {
            // color: #000;
            // background-color: #e8ecfa;
            // clip-path: polygon(14% 0%, 100% 0%, 100% 50%, 100% 100%, 14% 100%, 0% 50%);
    // }
  }
  .hexagon-active {
    color: #fff !important;
    background-color: #9492e9 !important;
  }
  .hexagon-lost {
    color: #fff !important;
    background-color: #d42d17 !important;
  }
  .hexagon:first-child {
    margin-left: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 0% 50%);
  }
  .hexagon:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%, 14% 50%);
  }
  // .hexagon:nth-last-child(2) {
  //   border-top-right-radius: 10px;
  //   border-bottom-right-radius: 10px;
  //   clip-path: polygon(0% 0%, 100% 0%, 85% 50%, 100% 100%, 0% 100%, 14% 50%);
  // }
  // .lost-arrow-container{
  //   display: flex;
  //   align-items: center;
  //   width: 85%;
  //   .hexagon {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin-left: -25px;
  //     font-size: 12px;
  //     width: 20%;
  //     color: #fff;
  //     height: 28px;
  //     background-color: #E44862;
  //     clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 14% 50%);
  //     &:nth-child(1) {
  //       margin-left: 0px;
  //       clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%, 0% 50%);
  //     }
  //     &:nth-child(3) {
  //       color: #fff;
  //       clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%, 14% 50%);
  //     }
  //   }
  //   .hexagon:first-child {
  //     border-top-left-radius: 10px;
  //     border-bottom-left-radius: 10px;
  //   }
  //   .hexagon:last-child {
  //     border-top-right-radius: 10px;
  //     border-bottom-right-radius: 10px;
  //     clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 1% 100%, 14% 100%, 0% 50%);
  //   }
  // }
  .newstatusupdate{
    display: flex;
    align-items: center;
    background: #473391;
    height: 32px;
    color: #fff;
    border: none;
    border-radius: 20px;
    font-size: 11px;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
  }
.mainContainer{
    width:500px;
    height:250px;
    border-radius: 20px;
    padding:30px;
    
}

.heading{
    font-family: poppins;
    font-size: 16px;
    font-weight: 500;
}

.contentSection{
    margin-top: 40px;
    padding:0px 20px;
}

.contentSection input{
    height:40px;
    padding:5px 10px;
    border-radius: 12px;
    border:1px solid #cccccc;
}

.contentSection button{
    height:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin:auto;
    margin-top: 40px;
    width:50%;
    padding:10px 20px;
}

.nameSection,.bankSection{
    display:flex;
    justify-content: start;
    align-items: center;
    gap:20px;
}

.nameSection{
    margin-top: -20px;
}

.bankSection{
    margin-top: 10px;
    align-items: flex-end;
}

.inputSection{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap:2px;
}

.inputLabel{
    font-size: 10px;
}

.inputContainer{
    border:2px solid #e8e8e8;
    padding:4px 8px;
    font-size: 12px;
}

.removeButton{
    margin-top: 15px;
    font-size: 12px;
    font-weight: 500;
    color:white;
    background-color: #9a4f4f;
    border-radius: 10px;
    padding:6px 20px;
    border:none;
    cursor: pointer;
}


.contentSection{
    margin-top: -20px;
    height:280px;
    display:flex;
    justify-content: space-between;
    align-items: start;
}

.horizontalLine{
    height:100%;
    border:2px solid #e8e8e8;
}

.loanDetailSection,.feeDetailSection{
    width:30%;
}

.loanDetailsContainer,.feeDetailsContainer{
    margin-top:10px;
    display:grid;
    grid-template-columns: repeat(1,auto);
    gap:10px;
    .inputContainer{
        width:100%;
    }
}

.feeDetailsContainer{
    height:250px;
    overflow-y: auto;
}

.remarkContainer{
    padding: 10px;
    border-radius: 10px;
    border: 3px solid #ddd;
    height:150px;
    overflow-y: auto;
}
.tippy-popper {
    .tippy-tooltip {
        background: $white !important;
        box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
        border-radius: 6px;
        color: $heading-color !important;
        padding: 20px !important;
    }

    .controlled-example_close-button {
        position: absolute;
        top: 0px;
        right: 5px;
        font-size: 18px;
        cursor: pointer;
    }

    .converter-tootip {
        color: rgba($heading-color, 0.6);
    }
}

.tippy-popper[x-placement^=bottom] [x-arrow].arrow-small {
    border-bottom: 5px solid $white !important;
}

.tippy-popper[x-placement^=top] [x-arrow].arrow-small {
    border-top: 5px solid $white !important;
}

.tippy-popper[x-placement^=left] [x-arrow].arrow-small {
    border-left: 5px solid $white !important;
}

.tippy-popper[x-placement^=right] [x-arrow].arrow-small {
    border-right: 5px solid $white !important;
}



.tooltip {
    position: relative;
    display: inline-block;
    text-align: left;
  }

.tooltip .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
   visibility: visible;
   opacity: 1;
   padding: 10px;
   text-align: left;
}


  
.badge {
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 10px;
    color: white;
    font-weight: bold;
    display: inline-block;
    text-align: center;
  }
  
  .badge[data-value='Connected'] {
    background-color: #00A300;
  }
  
  .badge[data-value='Missed'] {
    background-color: #ff2c2c;
  }

  .badge[data-value]:not([data-value='Connected']):not([data-value='Missed']) {
    background-color: #3944BC;
}
  



.container {
    width: 600px;
    min-height: 300px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.headerSection {
    width: 100%;
    display: flex;
    justify-content: start;
}

.heading {
    font-size: 20px;
    font-weight: 500;
    color: black;
    margin-bottom: 20px;
}

.contentSection {
    width: 100%;
    position: relative;
}

.remarkLabel {
    position: absolute;
    top: -8px;
    left: 20px;
    font-size: 12px;
    margin-bottom: 5px;
    color: #666;
    background: #FFF;
    padding:0px 5px;
}

.remarkArea {
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    resize: none;
    outline: none;
    padding:20px;
}

.submitButton {
    border: none;
    border-radius: 20px;
    padding: 8px 30px;
    background-color: #473391;
    margin-top: 20px;
    font-size: 12px;
    color: white;
    cursor: pointer;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;

  .modal-main {
    position: fixed;
    background: white;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal-height{
    overflow: hidden;
    overflow-y: auto;
    height: 500px;
    // scrollbar-width: none; 
  }
  .close_icn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 21px;
    cursor: pointer;
  }
  .modal-body {
    max-height: 450px;
    overflow-y: auto;
    width: calc(100% + 80px);
    // margin-left: -48px;
    padding: 0px 40px;

    h4 {
      margin-bottom: 1rem;
    }
  }
  .modal-mobile-height{
    overflow: hidden;
    overflow-y: auto;
    height: auto;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.view-timeline-popup {
  .modal-body {
    max-height: 450px;
    overflow-y: hidden;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }
  .modal-main {
    border-radius: 8px;
    min-width: 550px;
    padding: 40px 40px;
    h2 {
      padding: 0px 0 20px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
    h3 {
      font-weight: 500;
      font-size: 16px;
      color: #000;
    }
  }
  .timeline-history-tabel {
    max-height: 230px;
    overflow-y: auto;
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
    padding: 0px 40px;
  }
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;
    tbody {
      tr {
        background: #eff2f9;
        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }
          &:nth-child(2) {
            width: calc(100% - 80px);
            border-radius: 8px;
          }
          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }

          .date {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $heading-color;
            display: block;
          }
          .time {
            font-size: 10px;
            line-height: 20px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
          .remarkdiv {
            display: block;
            float: left;
            width: 50%;
          }
          .sectiondiv{
            display: block;
            float: left;
            padding-left: 10px;
            width: 50%;
          }
          .heading {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: $heading-color;
            display: block;
            font-weight: 400;
            word-break: break-word;
          }
          .subheading {
            font-size: 11px;
            line-height: 18px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
        }
        &:hover {
          background: $backgroundcolor;
        }
      }
    }
  }
  &.doc-history-timeline {
    table {
      tbody {
        tr {
          td {
            padding: 14px 15px;
            &:nth-child(3) {
              border-radius: 0 !important;
              width: 90px;
            }
            &:nth-child(4) {
              border-radius: 0 8px 8px 0 !important;
              vertical-align: text-bottom;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .tab-line {
    .tab-list {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      &::after {
        display: none;
      }
    }
  }
  .modal-mobile-main {
    border-radius: 8px;
    width: 90%;
    max-width: 530px;
    padding: 25px;
    position: fixed;
    background: white;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.view-upload-mis-popup {
  .modal-body {
    max-height: 450px;
    overflow-y: hidden;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }
  .modal-main {
    border-radius: 8px;
    min-width: 550px;
    padding: 40px 40px;
    h2 {
      padding: 0px 0 20px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
    h3 {
      font-weight: 500;
      font-size: 16px;
      color: #000;
    }
  }
  .timeline-history-tabel {
    max-height: 230px;
    overflow-y: auto;
    width: calc(100% + 80px);
    margin-left: -40px;
    margin-right: -40px;
    padding: 0px 40px;
  }
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;
    tbody {
      tr {
        background: #eff2f9;
        td {
          font-size: 11px;
          line-height: 18px;
          // color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            text-align: center;
            padding-left: 0px;
          }
          &:nth-child(2) {
            width: calc(100% - 80px);
          }

          .date {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $heading-color;
            display: block;
          }
          .time {
            font-size: 10px;
            line-height: 20px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
          .remarkdiv {
            display: block;
            float: left;
            width: 50%;
          }
          .sectiondiv{
            display: block;
            float: left;
            padding-left: 10px;
            width: 50%;
          }
          .heading {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: $heading-color;
            display: block;
            font-weight: 400;
            word-break: break-word;
          }
          .subheading {
            font-size: 11px;
            line-height: 18px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
        }
        &:hover {
          background: $backgroundcolor;
        }
      }
    }
  }
  &.doc-history-timeline {
    table {
      tbody {
        tr {
          td {
            padding: 14px 15px;
            &:nth-child(3) {
              border-radius: 0 !important;
              width: 90px;
            }
            &:nth-child(4) {
              border-radius: 0 8px 8px 0 !important;
              vertical-align: text-bottom;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .tab-line {
    .tab-list {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      &::after {
        display: none;
      }
    }
  }
  .modal-mobile-main {
    border-radius: 8px;
    width: 90%;
    max-width: 530px;
    padding: 25px;
    position: fixed;
    background: white;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.remark-heading-txt {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: $heading-color;
  padding: 25px 0 13px;
  display: block;
}

.mark-lost-popup {
  .modal-main {
    border-radius: 8px;
    width: 550px;
    padding: 40px 40px;
    h2 {
      padding: 0px 0 20px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
  }
  .modal-body {
    padding-top: 7px;
    overflow-y: inherit;
  }
  fieldset {
    margin-bottom: 20px;
  }
  .increase-width {
    width: 80% !important;
  }
}

.image-timeline-popup {
  .modal-body {
    overflow-y: auto;
  }
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;
    tbody {
      tr {
        background: #eff2f9;
        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }
          &:nth-child(2) {
            width: 230px;
            border-radius: 8px 0 0 8px;
          }
          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  }
}

.view-loggedin-popup {
  // .modal {
  //   position: fixed;
  //   top: 140px;
  //   right: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.6);
  //   z-index: 9;
  // }

//   .logged-main{
//     position: fixed;
//     top: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     max-height:100%;
//     max-width:540px;
//     background-color:#fff;
//     z-index:101;
//     overflow: hidden;
//     overflow-y: auto;
// }

// .backdrop{
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color:rgba(0,0,0,0.5);
//   z-index:99;
// }

  .logged-main {
    // border-radius: 8px;
    width: 600px;
    padding: 60px 40px;
    height: 100%;
    right: 0;
    overflow: hidden !important;
    overflow-y: auto !important;
  }

  .popup-overlay h2 {
      padding: 0px 0 20px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
  

  .logged-body {
    padding-top: 7px;
    overflow-y: inherit;
  }
  fieldset {
    margin-bottom: 20px;
  }
}

.image-loggedin-popup {
  .modal-body {
    overflow-y: auto;
  }
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;
    tbody {
      tr {
        background: #eff2f9;
        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }
          &:nth-child(2) {
            width: 230px;
            border-radius: 8px 0 0 8px;
          }
          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  }
}

.roll-back-confirmation{
  .btn-primary{
    min-width: 190px !important;
  }
}

.logginpopup fieldset {
  margin-bottom: 20px;
}
.logginpopup {
  position: fixed;
  top: 80px;
  right: 0;
  width: 100%;
  max-height: calc(100% - 80px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 900;
}
.logginpopup-cotant {
  background-color: #fff;
  width: 50%;
  // max-width: 600px;
  padding: 40px;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}
.logginpopup-cotant h2{
  margin-bottom: 20px; 
}
.close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  font-size: 30px;
}
// -------------------------------------------------------------------------
.Emailpopup{
 position: fixed;
  top: 80px;
  right: 0;
  width: 100%;
  max-height: calc(100% - 80px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 900;

}
.Emailpopup-cotant {
  background-color: #fff;
  width: 80%;
  // max-width: 600px;
  padding: 0px;
  // height: 100%;
  overflow: hidden;
  overflow-y: auto;
}


// ---------------------------Email popup css----------------------------------

.emailpopup_conatiner{
  padding: 0;
  display: flex;
  height: 100%;
}
.emailstickycont{
  position: sticky;
  top: 0;
  background-color: #fff;
}
.left_container{
  width: 35%;
  border-right: 1px solid #CCCCCC;
  // height: 100%;
  height: 89.5vh;
}
.Compose_email {  
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0 0;
  font-size: 14px;
  margin: 0 35px 0 30px;
  align-items: center;
  cursor: pointer;
  position: sticky;
  top: 0; /* Adjust to the desired offset from the top */
  background-color: white; /* Add a background color to cover content behind */
  z-index: 10; /* Ensure it appears above other elements */
}
.Compose_email fieldset {
  margin: 0;
  border-radius: 30px;
}

.select-template{
  .material{
   .__control--is-focused{
    border-radius: 20px !important;
    border-color: #6D50D8 !important;
    box-shadow: none;
   }
  }
  .css-t3ipsp-control:focus-visible{
    border-radius: 20px;
    // border-color: #6D50D8 !important;
  }
}

.select-radius{
  border-radius: 20px !important;
  .css-13cymwt-control{
    border-radius: 20px;
    // border-color: #6D50D8 !important;

  }
  // .css-b62m3t-container:focus-visible{
  //   border-radius: 20px;
  //   border: 1px solid red;
  // }
}

.emailinbox{
  font-size: 18px;
  color: #000000;
  font-weight: 400;
}
.formsyinEMAIL { 
width: 15vw;
}
.compose_button{
  border: 2px solid blue;
  border-radius: 10px;
  padding: 3px 7px;
}
.email_tabs{
  background: #6D50D8;
  display: flex;
  margin: 14px 20px;
  justify-content: space-evenly;
  padding: 5px 3px;
  border-radius: 12px;

}
.email_tab{
  font-size: 12px;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
}

.email_tab.active {
  background: white;
  color: black;
  border-radius: 8px;
}
.Recieve_Email_scroller {
  scroll-behavior: smooth;
  max-height: 69%; 
  // max-height: 760px;
  overflow-y: auto;
}

.Email_textContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid rgb(178, 178, 178); 
  padding: 20px 0 9px 0;
}
.Email_textContainer:hover {
  background-color: #f2f5ff;  
  box-shadow: 
    inset 1px 0 0 #dadce0,  
    0 1px 2px 0 rgba(60, 64, 67, 0.3),  
    0 1px 3px 1px rgba(60, 64, 67, 0.15); 
}




.Email_textContainer:last-child {
  border-bottom: 1px solid rgb(178, 178, 178);
}
.Email_textContainer.selected {
  background-color: #E3E8F9; 
}
.dummyimgcont{
  margin: 0 10px 15px 15px;
}
.message_container{
  width: 100%;
  padding: 0 5px 0 0;
}
.name_cont{
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.timediv{
  font-weight: 500;
  font-size: 10px;
  color: #000000;
}
.timediv1{
  font-weight: 500;
  color: #000000;
  font-size: 12px;
}
.leaddoc_contain{
  display: flex;
  justify-content: space-between;
}
.Lead_text{
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  margin: 0px 0 5px 0;
}
.mess_text{
  font-size: 11px;
  font-weight: 400;
}
.attach_cont{
  margin: 12px 12px 0 0 ;
  text-align: center;
}
.attactfilediv{
  cursor: pointer;
  margin-top: 12px;
}
// ------------no email cont-------------------
.noemail_added{
font-size: 15px;
font-weight: 500;
padding: 2px 0 0 20px;
color: #000000;
margin-left: 5px;
}
.confirm_mail button{
 padding: 8px 30px;
 border-radius: 8px;
 background: #6D50D8;
 border: 1px solid #6D50D8;
 color: #fff;
 cursor: pointer;
}
.confirm_mail input{
  padding: 8px 4px 8px 4px;
  margin: 15px 15px 0 0; 
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;
  width: 62%;
  color: #000000;
  border: 1px solid #CCCCCC;
}
.right_main_container{
  width: 65%;
  height: 90vh;
  overflow-x: auto;
  // margin-top: 50px;
  // border-top: 1px solid grey;
}
.right_inbox_cont_main{
  overflow-y: auto;
  scroll-behavior: smooth;
  // overflow: hidden;
  height: auto;
 // min-height: 85vh;

  // background-color: burlywood;
}
.right_inbox_cont {
  padding: 0 40px;
  scroll-behavior: smooth; 
  overflow-y: auto; 
  overflow-x: hidden; 
  // flex-grow: 1; 
  //min-height: 83%;
}

// -----------------NEwCompose popup css----------------------

.NewCompose_main_container{
  z-index: 900;
  width: 49%;
  position: fixed;
  bottom: 0;
  right: 25px;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  border-top-right-radius: 20px; 
  border-top-left-radius: 20px;
  border: 1px solid #ECECEC;
  box-shadow: 0px 4px 14px 0px #00000029;
}
.cross_conatienr{
  border-top-right-radius: 20px; 
  border-top-left-radius: 20px;
  background: #F6F8FF;
  display: flex;
  align-items: baseline;
  justify-content: space-between;  
  padding: 12px 25px;
}
.to_cont {
  display: flex;
  align-items: center;
  // gap: 5px; 
  font-size: 14px;
  border-top:1px solid #ECECEC;
  border-bottom:1px solid #ECECEC;
  padding: 5px 10px;
}

.to_input {
  padding: 5px;
  font-size: 14px;
  border: 0;
  border-radius: 4px;
  outline: none;
  width: 93%;
}
.subject_input{
  padding: 5px;
  font-size: 14px;
  border: 0;
  border-radius: 4px;
  outline: none;
  width: 90%;
}
.full_input_container {
  width: 100%; 
  height: auto;
}

.full_textarea {
  width: 100%; 
  height: 255px;
  max-height: 310px; 
  padding: 10px;
  font-size: 16px;
  border: 0;
  border-radius: 4px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box; 
  resize: none; 
  overflow-y: auto;
  scroll-behavior: smooth;
}

.Send_cont{
  display: flex;
  justify-content: space-between;
  margin: 0 40px 0 10px;
  align-items: center;
  height: 70px;
}
.send_btn button{
  border: 0;
  padding: 10px 50px;
  margin: 0 0 0 10px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  background: #6D50D8;
  border-radius: 8px;
  cursor: pointer;
}
.attechedfillcount{
  display: flex;
  justify-content: end;
}
.attchDoc_cont{
  align-items: center;
  display: flex;
  margin-right: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
}
.attchDoc_cont img{
  cursor: pointer;
}
.attach_cont input{
  display: none;
}
.attach_cont label{
  display: flex;
  cursor: pointer;
}
.attach_doc{
  display: flex;
  align-items: center;
  width: 135px;
  cursor: pointer;
  justify-content: space-between;
  border: 1px solid #6D50D8;
  border-radius: 8px;
  padding: 3px 12px;
}
.attach_doc:hover{
  background-color: rgba(32, 33, 36, .06);
}
#Uloadfil{
  display: none;
}
.mainhead_cont{
  display: flex;
  margin: 2px 30px 5px 0;
  align-items: center;
}
.mainhead_cont div img{
  cursor: pointer;
}
.backsvglogo {
  width: 39px; /* Adjust this size as needed */
  height: 39px; /* Same as width for a perfect circle */
  border-radius: 50%;
  // padding: 4px;
  display: flex; /* Aligns content (image) in the center */
  justify-content: center; /* Horizontally centers the image */
  align-items: center; /* Vertically centers the image */
  cursor: pointer; /* Indicates interactivity */
  transition: .2s;
}
.backsvglogo:hover{
  
  background-color: #ececec;
}

.mail_heading{
  font-size: 20px;
  font-weight: 600;
  // margin-bottom: 7px;
  margin-left: 25px;
  align-items: center;
  color: #000000;
}
.mail_usernam{
  font-size: 14px ;
  font-weight: 500;
}
.profil_cont_main{
  display: flex;
  justify-content: space-between;
  margin: 0px 0px;
}
.profil_cont{
  display: flex;
  align-items: center;
}
.mail_user_info{
  margin: 0 0px 0 15px;
}
.mail_head{
  font-size: 18px;
  margin: 10px 0px;
  color: #000000;
  font-weight: 500;
}
.mail_body{
  margin: 15px 0px;
}
.regards_line{
  margin: 15px 0px 3px 0;
}
.use_name{
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
}
.attachment_count{
  font-size: 12px;
  color: #000000;
  margin-top: 10px;
  font-weight: 500;
}
.pdf_main_cont {
  display: flex;
  // margin-bottom: 15px;
  overflow-x: auto; 
  white-space: nowrap; 
}
.pdf_cont{
  display: flex;
  border: 1px solid #CCCCCC;
  font-size: 10px;
  padding: 10px 10px 8px 10px;
  margin: 10px 15px 20px 0px;
  border-radius: 12px;
  min-width: 20%;
  cursor: pointer;
}
.Download_pdfbtn, .add_sngmbtn {
  opacity: 0; 
  transition: opacity 0.3s ease; 
  position: absolute;
}

.pdf_cont:hover .Download_pdfbtn,
.pdf_cont:hover .add_sngmbtn {
  opacity: 1; 
}

.Download_pdfbtn {
  position: absolute;
  transform: translateY(-109%);
  z-index: 1000;
}
.add_sngmbtn {
  transform: translate(199%, -109%);
}
.pdf_cont:hover{
  background-color: rgba(0, 0, 0, 0.6); 
}
.pdf_cont img{
  margin-right: 5px;
}
.pdf_siz_cont{
  position: relative;
  margin: 0 0 0 10px;
}
.pdf_name{
  color: #000000;
  font-size: 13px;
}
.reply_maincont{
  border-top: 1px solid #CCCCCC;
  display: flex;
  padding: 35px 0 30px 35px;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.reply_maincont button{
  border-radius: 16px;
  padding: 5px 23px;
  font-size: 11px;
  cursor: pointer;
  border: 1px solid #CCCCCC;
  margin-right: 20px;
  background-color: transparent;
}
.reply_maincont button:hover{
  background-color: rgba(32, 33, 36, .06);
}
.reply_maincont button img{
  margin-right: 5px;
}

// -----------------Reply popup css----------------------
.Reply_main_container{
  z-index: 900;
  min-height: 300px;
  width: 49%;
  position: fixed;
  bottom: 0;
  right: 25px;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  border-top-right-radius: 20px; 
  border-top-left-radius: 20px;
  border: 1px solid #ECECEC;
  box-shadow: 0px 4px 14px 0px #00000029;
}
.Cc_cont{
  cursor: pointer;
  float: right;
  text-align: right;
}
.Reply_cont{
  font-size: 13px;
  font-weight: 500;
  color: #000000;
}
.Reply_cont img{
    margin: 0 10px 0 0 ;
}
.Cursor_Pointer{
 cursor: pointer;
}
.reply_textarea {
  width: 100%; 
  height: 34vh;
  padding: 10px;
  font-size: 16px;
  border: 0;
  border-radius: 4px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box; 
  resize: none; 
  overflow-wrap: break-word; 
  scroll-behavior: smooth;
}

.modal-content-container{
  padding: 20px;
  margin-top: 40px;
  .content-question{
    h3{
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .content-button{
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    margin-right: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .contentyesbutton{
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    color: #fff;
    margin-right: 20px;
    background: #473391;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .contentnobutton{
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px 20px;
    margin-right: 20px;
    background: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

}

.attachdoccontainer{
  z-index: 950;
  width: 49%;
  position: fixed;
  min-height: 550px;
  bottom: 60px;
  right: 25px;
  overflow: hidden;
  overflow-y: auto;
  background-color: white;
  border-top-right-radius: 20px; 
  border-top-left-radius: 20px;
  border: 1px solid #ECECEC;
  box-shadow: 0px 4px 14px 0px #00000029;
}
.attachpadding{
  padding: 20px;
}
.attachdocupload{
  color: #6D50D8;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 20px;
}
.attachdone{
  display: flex;
  gap: 10px;
  color: #6D50D8;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.docheight{
  height: 400px;
  overflow-y: auto;
}
.doccategory{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(239, 239, 248);
  padding: 10px 20px;
  border-radius: 6px;
  margin-bottom: 10px;
  font-size: 14px;
  input{
    width: 20px;
    height: 20px;
  }
}
.doclisting{
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.doccard{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  background: rgb(239, 239, 248);
  width: 190px;
  img{
    height: 150px;
  }
}
.docselect{
  position: absolute;
  top: 20px;
  right: 20px;
  input{
    width: 20px;
    height: 20px;
  }
}

.register{
    background: $white;
    width: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 100px 50px;
    .tab-pane {
      padding-top: 24px;
      .material {
        .form-input {
          width: 100%;
          padding: 13px 45px 13px 60px;
          font-size: 13px;
          font-weight: 500;
        }
        input {
          margin-bottom: 20px;
        }
        .otp-time {
          font-size: 11px;
          line-height: 16px;
          color: rgba($heading-color, 0.5);
          position: absolute;
          right: 15px;
          top: 18px;
        }
        .form-label {
          &:before {
            left: 60px;
            top: 16px;
          }
        }
        .form-input:focus ~ label:before {
          top: -8px;
        }
        .form-input:not(:placeholder-shown) ~ label:before {
          top: -8px;
          font-size: 12px;
          background: #ffffff;
      }
        .icn-login {
          position: absolute;
          left: 23px;
          top: 14px;
          color: $heading-color;
          font-size: 21px;
        }
      }
    }
  }
  .login-outer {
    background: $backgroundcolor;
    align-items: center;
    justify-content: center;
    // border-radius: 20px;
    display: flex;
    height: 100vh;
    // width: 1040px;
    margin: 0 auto;
  }
  .login-main{
    background-color: #F4FCFF;
    width: 100%;
    .logo-img{
      width: 100%;
      padding: 50px 0;
    }
  }
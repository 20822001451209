.dashboard-outer {
    max-width: 1300px;
    margin: 0 auto;
    .heading {
        color: $heading-color;
        text-align: center;
        padding-top: 50px;
        h1 {
            font-weight: 600;
            font-size: 42px;
            line-height: 63px;
            margin-top: 20px;
        }
        .subheading {
            font-weight: normal;
            font-size: 22px;
            line-height: 27px;
            
        }
    }
    .search-type {
        margin: 0 auto;
        width: 25%;
    }
    .search-bx {
        position: relative;
        max-width: 400px;
        margin-left: 10px;
        // margin: 0 auto;
        // margin-top: 24px;
        // margin-bottom: 40px;
        input {
            // background: $lightpink;
            border-radius: 100px;
            width: 400px;
            padding: 10px 100px 10px 70px;
            height: 48px;
            border-color: $input;
            font-size: 15px;
            color: rgba($heading-color, 0.7);
        }
        .search-btn {
            background: $accent-color;
            border-radius: 0px 100px 100px 0px;
            height: 48px;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: $white;
            border: 1px solid $accent-color;
            display: inline-block;
            width: 100px;
            position: absolute;
            right: 0px;
        }
        .ic-search {
            font-size: 27px;
            position: absolute;
            left: 28px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .dashboardnewcard{
        border: 1px solid #ddd;
        padding-bottom: 20px;
        width: 100%;
        max-width: 1100px;
    }
    .dashboardheading{
        font-size: 24px;
        font-weight: 600;
        padding: 20px;
        margin-bottom: 0px;
        margin-left: 10px;
    }
    .dashboard-card {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0px -10px;
        gap: 20px;
       
        .card {
            border-radius: 15px;
            padding: 20px;
            width: 220px;
            height: 216px;
            margin: 20px 10px 0px 10px;
            .roung-bg {
                background: white;
                height: 56px;
                width: 56px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 0 auto;
                i {
                    font-size: 25px;
                    color: $heading-color;
                }
            }
        }
        .color-one {
            background: #bff8fc;
        }
        .color-two {
            background: #fff1d6;
        }
        .color-three {
            background: #ffece2;
        }

        .color-four {
            background: #d2fceb;
        }
        .color-five {
            background: #d8eaff;
        }

        .count-txt {
            font-weight: 500;
            font-size: 42px;
            line-height: 63px;
            text-align: center;
            letter-spacing: 0.5px;
            color: $heading-color;
            display: block;
            text-align: center;
            padding: 13px 0;
        }
        .card-txt {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            display: block;
            color: $heading-color;
        }
    }
    ::placeholder {
        font-size: 15px;
        color: rgba($heading-color, 0.7);
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 15px;
        color: rgba($heading-color, 0.7);
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        font-size: 15px;
        color: rgba($heading-color, 0.7);
    }
}
.dashboardflex{
    display: flex;
    justify-content: center;
}
.dashboardforyou{
    border: 1px solid #ddd;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.dashboard-cardnew {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0px -10px;
    gap: 20px;
   
    .card {
        border-radius: 15px;
        padding: 20px;
        width: 250px;
        height: 220px;
        margin: 20px 10px 0px 10px;
        .roung-bg {
            background: white;
            height: 56px;
            width: 56px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 0 auto;
            i {
                font-size: 25px;
                color: $heading-color;
            }
        }
    }
    .color-one {
        background: #bff8fc;
    }
    .color-two {
        background: #fff1d6;
    }
    .color-three {
        background: #ffece2;
    }

    .color-four {
        background: #d2fceb;
    }
    .color-five {
        background: #d8eaff;
    }

    .count-txt {
        font-weight: 500;
        font-size: 28px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $heading-color;
        display: block;
        text-align: center;
        padding: 13px 0;
    }
    .count-text-flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .count-show-text{
        border-right:1px solid #ddd;
        width: 50%;
    }
    .statustextfont{
        font-size: 12px;
    }
    .count-show-text:last-child{
        border-right:none;
    }
    .card-txt {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        display: block;
        color: $heading-color;
    }
}

footer {
    padding: 20px 0;
    .footer-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copyright-txt {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: rgba($heading-color, 0.4);
        }
    }
    .footer-link {
        display: flex;
        align-items: center;
        li {
            padding-left: 30px;
            position: relative;
            a {
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: rgba($heading-color, 0.4);
            }
            &:first-child {
                padding-right: 30px;
                &::after {
                    content: "";
                    width: 1px;
                    height: 17px;
                    background: rgba(0, 0, 0, 0.2);
                    position: absolute;
                    right: 0px;
                    top: 2px;
                }
            }
        }
    }
}

.dashboard-bg {
    padding-top: 50px;
    background: #fff;
    padding-bottom: 100px;
    margin-top: 4px;
}

.finance-toggle {
    .toggleApp {
        // width: 350px;
        margin: auto;
        margin-left: 10px;
    }

    .ToggleSwitch {
        background-color: #dbdfea;
        height: 18px;
        width: 35px;
        border-radius: 55px;
        &.active {
            background-color: $accent-color;
        }
    }
    .ToggleSwitch .knob {
        position: relative;
        width: 16px;
        height: 16px;
        background: $white;
        border-radius: 50%;
        left: 0em;
        transition: left 0.3s ease-out;
        top: 1px;
        &.active {
            background: $white;
            &::after {
                content: "";
                background: red;
            }
        }
    }
    .ToggleSwitch .knob.active {
        left: 18px;
    }
    .toggle-status {
        margin-left: 45px;
        margin-top: -16px;
        font-size: 12px;
        font-weight: normal;
    }
    .knob ~ .toggle-status {
        color: $heading-color;
    }
    .knob.active ~ .toggle-status {
        color: $heading-color;
    }
}
.dashboard-toggle-tab {
        display: flex;
        align-items: center;
        justify-content: center;
    .tab-list {
        display: flex;
        align-items: center;
    }
    .nav-pills {
        .nav-item {
            margin-right: 50px;
            a {
                background: transparent;
                font-size: 14px;
                font-weight: 500;
                border-bottom: 2px solid transparent;
                border-radius: 0px;
                padding: 0px;
                margin-right: 0px;
                &.active {
                    color: $accent-color;
                    border-color: $accent-color;
                    background: transparent;
                }
            }
        }
    }
}

// .dashboard-select-opt{

//     // max-width: 10px;
//     // margin: 0 auto;
//     // padding-top: 10px;
// }
.dashboardcommon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0px;
}
.commonselectbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.commonbtn {
    background: #00BDD6FF;
    padding: 4px;
    border-radius: 4px;
}
.self-btn {
    padding: 10px 20px;
    background: #00BDD6FF;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    color: #fff;
    margin-right: 10px;
}
.self-btn:last-child {
    margin-right:0px;
}
.self-btn.active {
    background: #fff;
    color: #00BDD6FF
}
.self-btn:hover {
    background: #fff;
    color: #00BDD6FF
}

.dashinput{
    width: 200px;
}

.createtabs{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    button{
        border: none;
        border-radius: 8px;
        margin-right: 10px;
        cursor: pointer;
    }
}

.ambakfulfilment {
    background-color: #fdf0cd;
    padding: 10px 20px;
    border-radius: 10px;
}

.createleads{
    display: flex;
    align-items: center
    ;
    justify-content: end;
}
.createbtn{
    padding: 8px 20px;
    border: 1px solid gray;
    border-radius: 30px;
    margin-top: -20px;
    cursor: pointer;
}
.tab-button{
    padding: 8px 16px;
    border-radius: 8px;
}
.tab-button.active {
    background-color: #6147FF;
    color: #fff;
    border: none !important;
}
.submitbtnflex{
    display: flex;
    align-items: center;
    justify-content: center;
}
.downloadformat{
    color: #6147FF;
    margin-bottom: 10px ;
    cursor: pointer;
    text-decoration: underline;
}
.orcsvfile{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.orcsvline{
    border: 1px solid #ccc;
    flex: 1;
}
.csvfileupload {
    position: relative;
    width: 600px;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .csvfileupload input[type='file'] {
    display: none;
  }
  .file-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .file-label span {
    font-size: 36px;
    margin-bottom: 20px;
  } 
  .file-label p {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }

/* VerifyOtpModal.css */
.lead-otp-verify-container {
    background: white;
    padding: 30px;
    border-radius: 10px;
    width: 400px; /* Increased width */
    text-align: center;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto; /* Center the modal */
}

.lead-otp-verify-input-container {
    margin: 20px 0;
}

.lead-otp-verify-otp-input {
    width: 100%;
    padding: 10px;
    font-size: 1.5rem;
    text-align: center;
    border: 2px solid #ddd;
    border-radius: 5px;
    letter-spacing: 0.5rem;
    box-sizing: border-box;
}

.lead-otp-verify-verify-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.lead-otp-verify-verify-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.lead-otp-verify-verify-button:not(:disabled):hover {
    background-color: #0056b3;
}



/* CSS for Pre-lead Activity Modal */

.pre-lead-activity {
    padding: 20px;
  }
  
  .pre-lead-activity h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .activity-log-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .activity-log-table th,
  .activity-log-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .activity-log-table th {
    background-color: #f4f4f4;
    color: #333;
    text-align: center;
  }
  
  .activity-log-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .activity-log-table tbody tr:hover {
    background-color: #f1f1f1;
  }

  .activity-details {
    display: block;
    /* margin-top: 5px; */
    color: #555;
    font-size: 0.9em;
  }
  
  .activity-details strong {
    color: #333;
  }

.import-responce-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}


/* ENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDENDEND */

/* CALLER DEATIL CARD CSS STARTS FROM HERE */
.wrapperdiv {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
}

.dropdowns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
}

.box {
  margin: 0;
  padding: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; /* Full width by default */
}

.dropdown-heading1,
.dropdown-heading2,
.dropdown-heading3 {
  margin-bottom: 5px;
  margin-top: 0;
  padding-top: 0;
  text-align: left;
  width: 100%;
}

.partnerdetailslist1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 20px;
}

.box select {
  padding: 6px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #000;
  width: 100%; /* Full width */
  height: 40px;
  border: none;
  font-size: 13px;
  border: 1px solid #8292a2;
  outline: none;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  
}

.box select option {
  padding: 30px;
  border: 1px solid #e9e8e8;
}

.date {
  margin: 5px;
  display: flex;
  flex-direction: column;
  width: 100%; /* Full width */
}



.partnerregisterbtn1 {
  background: #fff;
  color: #6147FE;
  border: 1px solid #6147FE;
  border-radius: 30px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-top: 15px;
}

.text-input {
  position: relative;
  margin-top: 7px;
  margin-left: 25px;
  width: 100%; /* Full width */
}

.text-input input[type="text"] {
  display: inline-block;
  width: 100%; /* Full width */
  height: 40px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 10px;
  transition: all 0.1s ease-out;
}

@media (min-width: 768px) {
  /* .box {
    width: 48%;
  } */

  .date,
  .text-input {
    width: 48%; /* Half width for larger screens */
    margin-left: 5px;
  }
}

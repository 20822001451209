.styles_scoreValue__2dBgK{
    display: none !important;
}
.styles_wrapper__3KXDn{
    height: 220px !important;
}
.cibilscorebox{
    position: relative;
}
.cibilnewcard{
    display:flex;
    align-items: center;
    gap: 10px;
    border:1px solid #ddd;
    border-radius:10px;
    padding:20px;
    font-size: 16px;
    justify-content: space-between;
    margin-bottom:20px;
}
.cibilcircle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.cibilcircle img {
    display: block;
}
.cibilcircle .textoverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #333;
    font-size: 20px;
}
.cibilscoreheading{
    font-weight: 600;
}
.cibilsubstatus{
    font-size: 12px;
    color: #6147FF;
}
.cibilscore{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.cibilscoreheading{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
.cibilupdated{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    span{
        font-size: 18px;
        font-weight: 600;
    }
}
.cibilhighscore{
    background: #e8f5fc;
    padding:12px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-right: 10px;
}
.cibilicon{
    display: flex;
    align-items: center;
}
.cibilhistory{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.cibilstatus{
    color:#000;
    font-size: 14px;
    font-weight: 600;
}
.cibilsubstatus{
    font-size: 12px;
}
.cibilcolorper{
    color: #6147FF;
    font-weight: 600;
    text-align: end;
}
.cibil-fetch-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $white;
    background: #6147FF;
    border-radius: 4px;
    height: 32px;
    align-items: center;
    justify-content: center;
    padding: 1px 12px;
    border: 1px solid #6147FF;
    cursor: pointer;
}
.breakuplistflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1px solid #ddd;
}

.headingbreak{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
}
.subheadingbreak{
    font-size: 10px;
}

.breakshowdetails{
    display: flex;
    justify-content: space-between;
   
}
.breakleftflot{
    width: 48%;
}
.breakrightflot{
    width: 48%;
    padding-left: 20px;
    border-left: 1px solid #ddd;
}
.mainheadingpayout{
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px;
}
.breakamountshow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.amountdetailsname{
    font-size: 12px;
}
.amountdetailsprice{
    font-size: 12px;
    font-weight: 600;
}
.amountdetailsname{
    font-size: 12px;
}
.amountdetailsprice{
    font-size: 12px;
    font-weight: 600;
}
.showhistory{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.notfoundpayout{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
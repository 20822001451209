.whatsappheader{
    border-bottom: 1px solid gray ;
    padding: 40px 0px 10px 0px;
    background: #fff;
    z-index: 2;
    position: fixed;
    top: 80px;
    max-height: calc(100% - 80px);
    width: 45%;
    // max-width: 850px;
}
.whatsappname{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 40px;
    height: 40px;
    background: #F3AD59FF;
    border-radius: 50%;
    margin-right: 10px;
}
.selfchat{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background: #000;
    border-radius: 50%;
    margin-left: 5px; 
    align-self: flex-end;
}
.otherchat{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background: #02240c;
    border-radius: 50%;
    margin-right: 5px; 
    align-self: flex-end;  
}
.mychat{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background: #f3ad59;
    border-radius: 50%;
    margin-right: 5px; 
    align-self: flex-end;  
}
.detailsname{
    color: #000;
    font-size: 14px;
}
.whatsappdetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.templatesbtn{
    height: 38px;
    padding: 10px 20px;
    border: 1px solid #6147FEFF ;
    color:  #6147FEFF;
    border-radius: 30px;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.chatsdate{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    margin: 70px 0px 10px 0px;
}
.chatsviewright {
    padding: 8px 16px;
    background:  #F3F4F6FF;
    border-radius: 4px;
    font-size: 13px;
    max-width: 80%;
    margin-top: 10px;
    margin-left: auto;
}
.chattime{
    display: flex;
    justify-content: flex-end;
    color: gray;
    font-size: 11px;
}
.tickSign{
    display: flex;
    justify-content: flex-end;
    // color: rgb(121, 121, 121);
    // font-weight: 500;
    font-size: 15px;
}
.dateTimewhatsapp{
    font-size: 12px;
}
.failedicon{
    cursor: pointer;
}
.leftchats{
    margin-bottom: 40px;
}
.rightchats{
    margin-bottom: 40px;
}
.chatsviewleft {
    padding: 8px 16px;
    background:  #E7E3F0FF;
    font-size: 13px;
    border-radius: 4px;
    max-width: 70%;
    margin-right: auto;
}
.chatsviewright {
    padding: 8px 16px;
    background:  #E7E3F0FF;
    font-size: 13px;
    border-radius: 4px;
    max-width: 70%;
    margin-left: auto;
}
.chatmsgdetails{
    display: flex;
    margin-bottom: 10px;
}
.sendchatsdetails{
    margin-top: 10px;
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: 44%;
    min-height: 90px;
    //max-width: 520px;
}
.inputbox{
    display: flex;
    align-items: center;
}
.attachiconwidth{
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
}
.sendinput {
    display: flex;
    align-items: center;
    background: #F3F4F5FF;
    border-radius: 4px;
    padding: 10px;
    height: auto;
    width: 100%;
}

.sendinput textarea {
    border: none;
    background: #F3F4F5FF;
    // padding:10px;
    width: 100%;
    height: 100%;
    max-height: 60px;
    resize: none;
}
.sendinput span{
    margin-left: 10px;
}
.sendinput img{
   cursor: pointer;
}
.ic-attach_file {
    width: 20px;
    height: 20px;
    cursor: pointer;
    input{
        display: none;
    }
}
.sendstatus{
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
    cursor: pointer;
}

// --------------------new whatsapp deagin css-------------------------------


.wahtsapp_main_container{
    height: 100%;
}
.Whatspp_header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 15px 35px 10px 30px;
    align-items: center;
    position: sticky;
    top: 0; 
    border-bottom: 1px solid #8F8F8F;
    background: #f0f2f5;
    z-index: 10;
  }
  .Whatspp_header fieldset {
    margin: 0;
    cursor: pointer;
  }
  .whatsappInbox{
    font-size: 16px;
    font-weight: 500;
  }
  .headingforSelect{
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px 10px;
  }
  .fguifui{
    height: 100px !important;
  }
  .select_tempWhatsapp{
    margin-right: 30px;
    min-width: 14vw;
  }
  .mess_main_cont {
    flex: 1;
    padding: 30px;
    overflow-y: auto; 
    display: flex;
    flex-direction: column;
    min-height: 83.5%;
    max-height: calc(98vh - 230px); 
    
    background-image: url('../../images/whatsappBack.png'); 
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center; 
    backdrop-filter: blur(5px);
    
}

.recievd_messs {
    align-self: flex-start; /* Align to the left */
    display: table;
    width: auto;
    max-width: 80%;
    padding: 12px 12px 7px 12px;
    border: 1px solid #DCD5D5;
    border-radius: 12px 12px 12px 0;
    font-size: 13px;
    color: #000;
    margin: 20px 0 0 0;
    background-color: #f9f9f9;
}
.send_messs {
    align-self: flex-end; 
    display: inline-block; 
    width: auto;
    max-width: 80%;
    padding: 12px 12px 7px 12px;
    border: 1px solid #DCD5D5;
    border-radius: 12px 12px 0px 12px;
    font-size: 13px;
    color: #000;
    margin: 10px 0 10px 0;
    background: #e0fec6;
    
}
.send_messs-container {
    display: flex;
    justify-content: flex-end;
    width: 100%; 
    flex-direction: column;
    justify-items: left;
    align-items: end;
    text-align: left;
}
.timestamp{
    border-radius: 12px;
    background-color: #FAFAFA;
    color: #02240c;
    border: 1px solid #ECECEC;
    position: sticky;
    padding: 7px 17px;
    font-size: 11px;
    font-weight: 500;
    color: #000;
    top: 0;
    align-self: center;
}
.timestampline {
    height: 1px;
    background: linear-gradient(90deg, #FFFFFF 0%, #CDCDCD 49%, #FFFFFF 100%);  
    margin: 0 auto; 
    width: 40%;
    position: relative; 
}

.foot_cont {
    flex-shrink: 0; /* Prevent the footer from shrinking */
    // position: sticky;
    position: absolute;

    bottom: 0; /* Fix the footer to the bottom */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding:6px  5px;
    width: 50%;
    min-height: 8.5vh;
    border-top: 1px solid #8F8F8F;
    background-color: #f0f2f5;
}
.send_input{
    border: none;
    width: 100%;

}
.send_input textarea {
    margin: 5px;
    padding: 5px;
    border: none;
    width: 96%;
    background-color: transparent;
    border-right: 1px solid #8F8F8F;
    resize: none; /* Disable manual resizing */
    overflow-y: auto; 
    scroll-behavior: smooth;
    height: 28px; /* Let JS control the height */
    max-height: 200px;
  }
  
  
  

.send_arrow{
    cursor: pointer;
}
.send_input_cont{
    display: flex;
    width: 93%;
    padding: 0px 10px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    margin: 5px;
    border: 1px solid #8F8F8F;
    border-radius: 23px;
    background: #fff;
}

.send_input_cont_partner{
    display: flex;
    width: 78%;
    padding: 0px 10px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    margin: 5px;
    border: 1px solid #8F8F8F;
    border-radius: 23px;
    background: #fff;
}
.plus_cont{
    width: 7%;
    position: relative;
    cursor: pointer; 
}
.plus_cont img{
    cursor: pointer;
}
.input_file{
    position: absolute;
    top: -14px;
    left: 0; 
    width: 100%;
    height: 100% ;
    border-radius: 10%;
    opacity: 0; 
    cursor: pointer; 
    background-color: #000;
}
.plus_cont input{
    cursor: pointer;
}
// ------------------------------------------->
.error-message{
color: red;
margin-top: -15px;
font-size: 12px;
}
/* ✅ Modal Container */
.modern-modal {
    background: rgba(255, 255, 255, 0.9); /* Subtle White */
    padding: 25px;
    border-radius: 12px;
    width: 400px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    text-align: center;
    backdrop-filter: blur(10px); /* Smooth Blur Effect */
    border: 1px solid rgba(200, 200, 200, 0.3);
  }
  
  /* ✅ Modal Title */
  .modal-title {
    font-size: 22px;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  /* ✅ Input Group */
  .input-group {
    text-align: left;
    margin-bottom: 15px;
  }
  
  .input-group label {
    font-size: 14px;
    font-weight: 600;
    color: #555;
    display: block;
    margin-bottom: 5px;
  }
  
  /* ✅ Modern Input */
  .modern-input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    outline: none;
    font-size: 16px;
    transition: all 0.3s;
    background: rgba(255, 255, 255, 0.8);
  }
  
  .modern-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
  }
  
  /* ✅ Modern Select Dropdown */
  .modern-select {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    transition: all 0.3s;
  }
  
  .modern-select:focus {
    border-color: #007bff;
  }
  
  /* ✅ Buttons */
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .btn-cancel, .btn-update {
    width: 48%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  /* Cancel Button */
  .btn-cancel {
    background: #e74c3c;
    color: white;
  }
  
  .btn-cancel:hover {
    background: #c0392b;
  }
  
  /* Update Button */
  .btn-update {
    background: #007bff;
    color: white;
  }
  
  .btn-update:hover {
    background: #0056b3;
  }
  

  .btn-reset {
    background: transparent;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    border-radius: 30px;
    color: #000;
    border: 1px solid #000;
    background: #fff;
    height: 42px;
    min-width: 97px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.btn-reset:hover {
    background: #000;
    color: #fff;
}

.truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 300px;
 
  
}

.expanded-text {
  white-space: normal;
}

.laon-expect-outer {
    margin-top: 20px;
    display: flex;
    // margin-bottom: 20px;

    .row {
        margin-right: -10px;
        margin-left: -10px;
    }
    .col-md-6,
    .col-md-12 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .loan-expect-left-panle {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background: $white;
        width: calc(60% - 10px);
        padding: 20px 30px;
        margin-right: 10px;
    }
    .loan-expect-right-panel {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        background: $white;
        width: calc(40% - 10px);
        padding: 20px 30px;
        margin-left: 10px;
    }
    .heading-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: $heading-color;
        }
        .action-icn {
            ul {
                display: flex;
                align-items: center;
                li {
                    list-style: none;
                    padding-left: 15px;
                    color: $heading-color;
                    font-size: 18px;
                    cursor: pointer;
                    a {
                        color: $heading-color;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .loan-filed-sec {
        background: #ffffff;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        padding: 20px 30px;
        margin-bottom: 20px;
        .heading-dp-txt {
            padding-bottom: 20px;
            span {
                font-weight: 500;
                font-size: 22px;
                line-height: 30px;
                color: $heading-color;
            }
            &.d-flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .custom-control-label {
                    padding-left: 17px;
                }
            }
            .custom-control {
                margin-top: 6px;
            }
        }
        h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            padding-bottom: 16px;
            color: $heading-color;
        }
        &.m-t-20 {
            margin-top: 20px;
        }
        .btn-submit {
            &.btn-primary {
                min-width: 95px;
                height: 48px;
                line-height: 47px;
            }
        }
    }
    .form-group-bx {
        fieldset {
            margin-bottom: 20px;
        }
        textarea {
            resize: none;
        }
    }
    .material {
        input {
            &.form-input {
                height: 48px;
                &:read-only {
                    background: #f8f8f8;
                }
            }
        }
        .form-label {
            &:before {
                top: 15px;
            }
        }
        textarea {
            &:read-only {
                background: #f8f8f8;
            }
        }
    }
    h2 {
        padding-bottom: 20px;
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: $heading-color;
    }
    .react-datepicker__year .react-datepicker__year-text {
        padding: 5px 0;
    }
    .single-select {
        .css-1pahdxg-control,
        .css-yk16xz-control {
            height: 48px;
        }
        .css-26l3qy-menu {
            margin-top: 0px !important;
        }
    }
    .btn-disable {
        background: #919191;
        border-color: #919191;
        box-shadow: none;
    }
    .loan-form-filed-scroll {
        max-height: 1160px;
        overflow-y: auto;
        width: calc(100% + 50px);
        margin-left: -30px;
        padding: 0px 15px 0px 30px;
    }
    .required-dp-txt {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: $heading-color;
        padding-bottom: 16px;
    }
}

.m-t-10 {
    margin-top: 10px;
}

.material {
    input {
        &.form-input {
            height: 40px;
            &:read-only {
                background: #f8f8f8;
            }
        }
    }
    .form-label {
        &:before {
            top: 15px;
        }
    }
    textarea {
        &:read-only {
            background: #f8f8f8;
        }
    }
}

.lead-form-filed {
    .single-select {
        .css-1pahdxg-control,
        .css-yk16xz-control {
            height: 48px;
        }
        .css-26l3qy-menu {
            margin-top: 0px !important;
        }
    }
}


.cc-util-card{
    border-radius: 5px;
    margin-bottom: 20px; 
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    border-color: #d4d0d0;
    border-style: solid;
    border-width: 1px;
}

.cc-mt{
    margin-top: -21px
}

.cc-tb-cl{
    background-color: #E5E2FF;
    font-weight: bold
}

.loan-cd{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.loan-bold{
    font-weight: bold;
}

.loan-close {
    color: red;
}
.loan-active{
    color: green;
}

.cibilcard {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cibilbox {
    width: 100%;
    max-width: 600px;
    background: #fff;
    padding: 40px;
}

.cibilscoreheading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.cibilincomecard {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
}

.cibilincome {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
}

.cibilprice {
    text-align: right;
    font-weight: 600;
    margin-top: 20px;
}

.acountdetails {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 40px;
}

.cibilloancard {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 20px;
}

.totalaccount {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 40px;
    border-bottom: 1px solid #ddd;
    /* span{
        color: #6147FF;
        font-weight: 600;
        margin-left: 10px;
    } */
}

.totalaccount span {
    color: #6147FF;
    font-weight: 600;
    margin-left: 10px;
}

.accountommon {
    font-weight: 600;
    margin-bottom: 10px;
}

.activeaccount {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 30px;
    border-right: 1px solid #ddd;
}

.activeaccount:last-child {
    border-right: none;
    padding-right: 0px;
}

.cibilhistory {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.cibilicon {
    display: flex;
    align-items: center;
}

.cibilhighscore {
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-right: 10px;
}

.cibilstatus {
    color: #000;
    font-size: 15px;
    font-weight: 600;
}

.cibilsubstatus {
    font-size: 14px;
    color: gray;
}

.cibilcolorper {
    font-size: 14px;
    color: #6E55B8;
    font-weight: 600;
    text-align: end;
}

.loanaccountcardtwo {
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-top: 20px;
}

.homeloandetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #ddd;
}

.homeloandetails:last-child {
    border-bottom: none;
}

.commondetails {
    display: flex;
    align-items: center;
    font-weight: 600;
}

.activeaccountcolor {
    display: inline-block;
    height: 8px;
    width: 8px;
    background-color: #1AC18F;
    border-radius: 50%;
    margin-right: 5px;
}

.closeaccountcolor {
    display: inline-block;
    height: 8px;
    width: 8px;
    background-color: #E16B4D;
    border-radius: 50%;
    margin-right: 5px;
}

.homeloanmonthdetails {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px 0px;
}

.homeloanmonth {
    display: flex;
    align-items: center;
}

.paymenttype {
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: #1AC18F;
    border-radius: 20%;
    margin-right: 10px;
}

.latepaymenttype {
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: #3BADFF;
    border-radius: 20%;
    margin-right: 10px;
}

.paymentcount {
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
}

.latepaymentsummarycard {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 20px;
}

.paymentinfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.paymentinfolist {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ddd;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.paymentinfolistrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.activeaccounttotal {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 30px;
    border-right: 1px solid #ddd;
}

.activeaccounttotal:last-child {
    border-right: none;
}
.tablelist{
    margin-top: 20px;
}

.paymenthistorycircle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 300px;
    height: 300px;
}

.circle-container {
    position: relative;
    width: 100%;
    height: 100%;
}

svg.circle {
    transform: rotate(-90deg); /* Rotate to start the stroke from the top */
}

circle {
    fill: none;
    stroke-width: 20;
    stroke-linecap: round;
}

.background {
    stroke: #E6E7FF;
}

.foreground {
    stroke: #8E93FF; /* Default color for outer circle */
    transition: stroke-dashoffset 1s;
    stroke-dasharray: 880;
    // stroke-dashoffset:  "70%"; // dd
}

.backgroundtwo {
    stroke: #CCF1E6;
}

.foregroundtwo {
     /* Default color for inner circle */
     stroke: #1AC18F;
    transition: stroke-dashoffset 1s;
    stroke-dasharray: 628;
    // stroke-dashoffset:  300; //// dd
}

.payment-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.paymentpercente {
    font-size: 24px;
    color: #8E93FF;
}
.paymentlatepercente{
    font-size: 24px;
    color: #1AC18F;
}


.paymentcard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding:20px 40px 20px 20px;
    margin-top:10px;
    margin-bottom: 20px;
   }

   .paymenthistory {
       display: flex;
       align-items: center;
       margin-bottom: 20px;
       position: relative;
   }
   .paymenthistory img {
       width: 300px;
       height: auto;
   }
   .payment-text {
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       text-align: center;
       color: #000;
       font-size: 24px;
   }
   .statuscolor{
    display: flex;
    align-items: center;
    justify-content: center;
    background:#1AC18F;
    padding:10px 20px;
    border-radius: 10px;
    color:#fff;
    margin-bottom: 10px;
   }
   .mainheaingpayout{
    font-weight: 600;
    font-size: 28px;
   }
   .paymentstatus{
     text-align:center;
   }
   .paymentpercente{ 
     font-size:28px; 
     color:#8E93FF;
     font-weight:600;
   }
   .paymentlatepercente{
     font-size:28px; 
     color:#1AC18F;
     font-weight:600;
   }

   .cibilstatus {
       color: #6147FF !important;
       font-weight: 600;
   }

   .newleadcreate{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    padding: 10px 20px;
    background: #6147FF;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 10px;
   }

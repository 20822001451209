@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
.loan-bx-ribben {
  font-family: "Poppins", sans-serif;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  width: calc(100% + 30px);
  margin-left: -15px;
  // padding: 0px 15px;
  background: $white;
  margin-bottom: 10px;

  .maindetail-list {
    // padding-top: 5px;
    // padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .partner-item {
    width: 16%;
    padding:20px !important;
    font-size: 12px;
    border-right: 1px solid lightgray;

    &:nth-child(1) {
      width: 18%;
    }
    &:nth-child(2) {
      width: 18%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4) {
      width: 18%;
    }
    &:nth-child(5) {
      width: 18%;
      border-right: none;
    }
    &:nth-child(6) {
      width: 18;
      border-right: none;
    }

  }
  .newmaindetail-list {
    display: flex;
    flex-direction: row;
    gap:10px;
    flex-wrap: wrap;
    background: #E8ECFA;
    padding: 20px 0px;
  }
  
  .maindetail-item {
    background: #fff;
    width: 16%;
    padding:16px !important;
    font-size: 12px;
    border-radius: 20px;
    box-shadow: 0px 2px 8px 0px #0000000F;

    &:nth-child(1) {
      width: 22%;
    }
    &:nth-child(2) {
      width: 22%;
    }
    &:nth-child(3) {
      width: 17%;
    }
    &:nth-child(4) {
      width: 17%;
    }
    &:nth-child(5) {
      width: 18%;
    }
    &:nth-child(6) {
      width: 17;
    }

    // &:nth-child(1) {
    //   width: 8%;
    // }
    // &:nth-child(2) {
    //   width: 12%;
    // }
    // &:nth-child(3) {
    //   width: 15%;
    // }
    // &:nth-child(4) {
    //   width: 15%;
    // }
    // &:nth-child(4) {
    //   width: 12%;
    // }
    // &:nth-child(6) {
    //   width: 12;
    // }
    // &:nth-child(7) {
    //   width: 14%;
    // }
    // &:nth-child(10) {
    //   width: 11.5%;
    // }
    // &:nth-child(8) {
    //   padding-right: 0px !important;
    //   width: 8%;
    // }
    // &:nth-child(9) {
    //   width: 14%;
    // }
    // &:nth-child(11) {
    //   width: 15%;
    // }
    // &:nth-child(12) {
    //   width: 13%;
    // }
    // &:nth-child(14) {
    //   width: 12%;
    // }
    // &:nth-child(15) {
    //   width: 12%;
    // }
    .subheading {
      font-size: 11px;
      font-style: normal;
      margin-bottom: 3px;
    }
  }
  .newmaindetailscommon{
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
  }
  .newsubvalue{
    display: flex;
    font-size: 10px;
    color: #000;
    margin-bottom: 8px;
  }
  .newsubheading{
    font-size: 12px;
    font-weight: 500;
    color: #000;
  }
  .newsubheadingcut {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    color: #000;
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
  }

  .newallstatus{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .newallstatus:last-child{
    margin-bottom: 0px;
  }
  .newdetailsicon{
    display: flex;
    gap: 10px;
    align-items: center;
    img{
      cursor: pointer;
    }
  }
  .newassignedflex{
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .iconborder{
    width: 45%;
    border-left: 1px solid #EAEAEA;
    padding-left: 10px;
  }
  .newicon{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    img{
      cursor: pointer;
    }
  }
  .messagecount{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 10px;
    color: #000;
    margin-bottom: 10px;
  }
  .sub-value-list {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
    li {
      padding-right: 0px;
      font-size: 12px;
      color: $heading-color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: flex;
      .ic-info-icon {
        padding-left: 5px;
        position: relative;
        top: 1px;
      }
    }
  }
  .finance {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $white;
    background: #3bc04d;
    border-radius: 4px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    margin-left: 8px;
  }
  .re-finance {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $white;
    background: #0384f2;
    border-radius: 4px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    margin-left: 8px;
  }
}

.btn-primary-accent {
  &.history-btn {
    height: 24px;
    line-height: 13px;
    min-width: 100px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    margin-top: 5px;
    box-shadow: none;
    .ic-arrow_forward {
      margin-left: 5px;
      position: relative;
      top: 1px;
    }
  }
  &.tranche-status {
    margin-left: 1rem;
    margin-bottom: 0.2rem;
  }
}

.make-call-submenu {
  margin-top: 17px;
  position: relative;
  .dropdown {
    .dropdown-toggle {
      background: transparent;
      border: 0px;
      position: relative;
      font-size: 12px;
      color: $heading-color;
      font-weight: 400;
      &:after {
        content: "\e917";
        font-size: 24px;
        font-family: dc !important;
        position: absolute;
        top: -4px;
        margin-left: -2px;
      }
    }
    &.show {
      .dropdown-menu {
        display: block;
        right: 0px !important;
        transform: translate(-220px, 22px) !important;
        z-index: 9;
      }
      .dropdown-toggle {
        &:after {
          content: "\e91a";
        }
      }
    }
  }
  .make-call-dropdown-list {
    background: $white;
    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
    border-radius: 6px;
    position: absolute;
    width: 320px;
    top: 24px;
    z-index: 1;
  }

  .make-call-dropdown-list {
    ul {
      padding: 10px 20px;
      li {
        border-bottom: 1px solid rgba($heading-color, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        span {
          font-size: 12px;
          color: $heading-color;
          font-weight: normal;
        }
        a {
          background: #44d7b6;
          height: 30px;
          width: 53px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          padding: 0px;
          i {
            font-size: 15px;
            color: $white;
          }
          &.gray-bg {
            background: #eff2f9;
            i {
              color: $heading-color;
            }
          }
        }
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }
}
.tootip-outer {
  .react-tooltip-lite {
    background: $white;
    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
    border-radius: 6px;
    width: 350px !important;
    padding: 20px !important;
    // margin-left: 100px;
  }
  .react-tooltip-lite-arrow {
    border-bottom: 10px solid $white !important;
    // margin-left: 130px;
  }
  .controlled-example_close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }
  .user-detail {
    li {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 10px 0;
      span {
        font-size: 12px;
        line-height: 18px;
        width: 190px;
        padding-right: 10px;
        color: rgba($heading-color, 0.7);
      }
      label {
        font-size: 13px;
        width: calc(100% - 100px);
        font-size: 13px;
        color: $heading-color;
      }
    }
  }
}

.buyer-txt {
  max-width: 88%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltio-ic {
  // position: absolute;
  // right: 0;
  // top: 0;
  margin-left: 5px;
  position: relative;
  top: 1px;
}
.truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.call-history-popup {
  // position: fixed;
  // padding: 40px;
  // width: 730px;
  // background: $white;
  // border-radius: 15px;
  // left: 0;
  // right: 0;
  // margin: 0 auto;
  font-family: "Poppins", sans-serif;
  .call-history-table {
    padding-top: 10px;
    table {
      width: 100%;
      table-layout: fixed;
      thead {
        th {
          background: #e3e7f1;
          padding: 20px 0px 20px 30px;
          font-size: 12px;
          color: $heading-color;
          font-weight: 400;
          text-align: left;
          &:first-child {
            padding-left: 30px;
            border-radius: 6px 0 0 6px;
          }
          &:last-child {
            padding-right: 30px;
            border-radius: 0px 6px 6px 0px;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid rgba($heading-color, 0.1);
          &:last-child {
            border-bottom: 0px;
          }
        }
        td {
          font-size: 12px;
          line-height: 18px;
          padding: 20px 0px 20px 30px;
          color: rgba($heading-color, 0.6);
          text-align: left;
          &:first-child {
            padding-left: 30px;
            border-radius: 6px 0 0 6px;
          }
          &:last-child {
            padding-right: 30px;
          }
        }
      }
      th,
      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:nth-child(1) {
          width: 11%;
        }
        &:nth-child(2) {
          width: 12%;
        }
        &:nth-child(3) {
          width: 8%;
        }
        &:nth-child(4) {
          width: 9%;
        }
        &:nth-child(5) {
          width: 16%;
        }
      }
    }
    h6 {
      &.text-danger {
        padding: 20px;
        font-family: "Poppins", sans-serif;
      }
    }
  }
}

.dailer-history-popup {
  font-family: "Poppins", sans-serif;
  .modal-header {
    border: 0px;
    right: 10px;
    border: 0px;
    padding: 0px;
    /* display: none; */
    position: absolute;
    top: 10px;
    z-index: 9;
  }
  h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    font-family: "Poppins", sans-serif;
  }
  @media (min-width: 992px) {
    .modal-dialog {
      max-width: 850px;
    }
  }
  .modal-content {
    max-height: 580px;
    overflow-y: auto;
  }
  .modal-body {
    padding: 40px 40px 0;
  }
  .modal-header {
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.lead-history-modal {
  font-family: "Poppins", sans-serif;
  .modal-body {
    padding: 10px 40px 20px;
  }
  .view-history-tab {
    &.nav-tabs {
      border-bottom: 0px;
      margin-bottom: 20px;
      .nav-item {
        padding: 0px;
        margin-right: 40px;
        padding-bottom: 6px;
        a {
          font-weight: 400;
          color: rgba($heading-color, 0.87);
        }
      }
      .nav-item.active,
      .nav-item:hover,
      .nav-item:focus {
        border-color: #0bbddd;
        a {
          color: rgba(11, 189, 221, 0.87);
          font-weight: 600;
        }
      }
    }
  }
  .view-history-data {
    .timeline-badge {
      display: none;
    }
    .timeline {
      &:before {
        display: none;
      }
      .timeline-heading {
        padding: 0px;
        display: flex;
        border-bottom: 0px;
        padding-bottom: 10px;
      }
      li {
        &:hover {
          .edit-secr-user {
            background: #bff8fc;
          }
        }
      }
      .timeline-title {
        font-size: 12px;
        color: $heading-color !important;
        font-family: "Poppins", sans-serif;
      }
    }
    .timeline > li > .timeline-panel {
      padding-left: 0px;
    }
    .timeline-panel.loan-Customer {
      .edit-secL {
        width: 14%;
        margin-right: 40px;
        padding: 15px 0;
      }
      .edit-secR {
        width: 63%;
        padding: 15px 0;
      }
      .user-sec {
        width: 33%;
        padding: 15px 0;
      }
    }
  }
  .modal-header {
    border-bottom: 0px;
    padding: 40px 40px 0 40px;

    .modal-title {
      text-align: left;
      display: block;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      color: $heading-color;
      font-family: "Poppins", sans-serif;
    }
    .close {
      position: absolute;
      right: 24px;
      top: 24px;
    }
  }
  .edit-secr-user {
    width: calc(100% - 23%);
    background: rgba(239, 242, 249, 1);
    padding: 0px 20px;
    border-radius: 8px;
  }
  .comment-wrap {
    max-width: 450px;
    width: calc(100% + 25px);
    margin-left: -15px;
    padding: 0 0px 0 20px;
  }
}
.followuohistoryflex{
  display: flex;
  gap: 20px;
}
.followupwidth{
  width: 60%;
}
.followupleadid{
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.btn-primary-accent {
  background: #0bbddd;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  border: 1px solid #0bbddd;
}
.tooltip-user-detail {
  li {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px 0;
    span {
      font-size: 12px;
      line-height: 18px;
      width: 190px;
      padding-right: 10px;
      color: rgba($heading-color, 0.7);
    }
    label {
      text-align: left;
      width: 100%;
      font-size: 13px;
      color: $heading-color;
    }
  }
}

.tooltip-user-detail-span-label {
  li {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px 0;
    span {
      text-align: left;
      font-size: 12px;
      line-height: 18px;
      width: 190px;
      padding-right: 10px;
      color: rgba($heading-color, 0.7);
    }
    label {
      text-align: left;
      width: calc(100% - 100px);
      font-size: 13px;
      color: $heading-color;
    }
  }
}
.patnerlist{
  color: #000;
  font-weight: 600;
}
.partnerwhatsapp{
  display: flex;
  align-items: center;
}
.whatsappchat{
  color: green;
  font-size: 24px !important;
  cursor: pointer;
}
.partnerbtn{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0px;
}
.partnernewlead{
  background:#6CC47A;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}
.partnerregisterbtn{
  background: #fff;
  color: #6147FE;
  border: 1px solid #6147FE;
  border-radius: 30px;
  height: 32px ;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px;

}
.partnerlistflexcard{
  display: flex;
  gap: 20px;
}
.partnerfloatleft {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.tabsContainer1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tabsbetween1{
  display: flex;
}
.tabsbetween1 button{
  margin: 0 15px 0 0 ;
}
.partnerfloatright {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.partnerfloatleft > .search-wrap,
.partnerfloatright > .search-wrap {
  flex: 1;
}
.btnmarkdoneflex{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.markdonebutton{
  display: flex;
  align-items: center;
  background: #6147FE;
  color: #fff;
  padding: 10px;
  height: 32px;
  font-size: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
}

.partnerdetailslist{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px ;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 20px;
}
.partnerstatus{
  font-size: 12px;
  color:#E72215;
  margin-right: 10px;
}
.partnerrefreshbtn{
  font-size: 20px;
  cursor: pointer;
}

.next-queue-btn {
  background-color: #5D48F5;
  color: white;
  font-size: 14px;
  height: 32px;
  width: 100px;
  margin-top: 18px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: all 0.2s ease;
}

.next-queue-btn:hover {
  background-color: #4a37e5;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.next-queue-btn:active {
  background-color: #3729b5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.1);
  transform: translateY(1px);
}

.patrnermessage{
  background: #E7C6175C;
  padding: 5px;
  font-size: 12px;
  border-radius: 5px;
  margin-right: 10px;
}
.patrnermessageplay{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #E7C6175C;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 8px;
  margin-right: 10px;
 
  img{
    cursor: pointer;
  }
  .timer{
    width: 140px;
  }
}

.partnersavebtnflex{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.patnertemplate{
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.partnertemplatelist{
  position: sticky;
  top: -10px;
  background: #fff;
  border-bottom: 2px solid rgb(241, 241, 241);
  padding-bottom: 9px;
}
.partner-tabs .nav-link {
  border: none;
  color: #555;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  
}

.partner-tabs .nav-link:hover {
  color: #6147FE;
}

.partner-tabs .nav-link.active-tab {
  border-bottom: 2px solid #6147FE;
  color: #6147FE;
}

.ameyo-iframe {
  display: flex;
  justify-content: end;
  color: #FFF;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  background: #428adc;
}

.ameyo-icon-parent {
  position: fixed;
  bottom: 200px;
  right: 15px;
  z-index: 999;
}

.ameyo-icon {
  position: relative;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
  }
}

.calling-status-modal-content-container{
 

  .heading-1{
    font-size: 15px;
  }

  .radio-container{
    display: flex;;
    margin-block: 20px;
  }
  .radio-btn{
    display: flex;
    width: 200px;
    gap: 10px;

    input{
      
      width: 15px;
    }

    label{
      font-weight: 400;
    }
  }
  .calling-status-done-button{
    height: 32px;
    width: 140px;
    border-radius: 20px;
    background-color: $accent-color;
    color: white;
    border: none;
    margin: auto;
    margin-top: 20px;
    margin-left: 35%;
  }

  .info{
    display: flex;
    background:#FFFFF1;
    border: 1px solid #FFD517;
    padding: 10px 20px;
    border-radius: 14px;
    margin: auto;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    gap: 10px;
  }

  .not-connected-form-container{
    width: 500px;

    .not-connected-info{
      display: flex;
      align-items: center;
     
      border: 1px solid #1AC18F;
      border-radius: 14px;
      background-color: #F2FFF1;
      padding: 10px;
      font-size: 12px;
      margin:20px 0 10px 0;
  
      img{
        margin-right: 10px;
      }
    }
  }

  
}

.partnerbtnflexn{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0px;
}
.dismisbtn{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border: none;
  background:#473391;
  padding: 10px 20px ;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
}
.partnerheading{
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
}
.partnerheadingneww{

    height: 35px;
}
.partnercommonpd{
  padding-left: 15px;
}
.cibilloanflex {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  flex-wrap: wrap;
}
.cibilhometab {
  border: 1px solid #ddd;
  height: 42px;
  width: 100%;
  max-width: 130px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.3s, color 0.3s;
  white-space: nowrap;
}
.cibilhometab.active {
  border: 1px solid #473391;
  color: #473391;
} 

.collection-details{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap:20px;
  column-gap: 80px;
}
.collection-details-label{
  font-weight: 600;
}
.collection-amount-header{
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

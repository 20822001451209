.emailthreadcard{
    // display: flex;
    display: none;
    gap: 10px;
}
.emailfloatleft{
    width: 100%;
    width: 35%;
}
.emailfloatright{
   // padding-top: 50px;
    width: 85%;
    // border-left: 1px solid lightgray;
    padding-left: 20px;
}
.emailmainheading{
    font-size: 16px;  
}
.emailheadingticket{
    font-size: 24px;
    font-weight: 600;
    margin-top: 50px;
}
.create_tickectDIV{
    width: 90%;
}
.emailheadingticket1{
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    margin: 5% auto 0 17px;
    //  margin-top: 50px;
}
.addemail_bank_div{
    width: 60%;
    //margin: auto;
    text-align: center;
}
.empatyemailcard{
    padding: 40px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 4px 4px 0px #00000040;
    min-width: 500px;
}
.emailheadingempty{
    font-size: 14px;
    text-align: start;
    margin-bottom: 20px;
}
.btnconfirm{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 10px;
    font-size: 14px;
    height: 40px;
    border: none;
    padding: 10px 20px;
    background: #6D50D8;
    cursor: pointer;
}
.fetchbanker{
    font-size: 14px;
    margin-bottom: 20px;
}
.emailheadingticket2{
    font-size: 18px;
    font-weight: 400;
    width: 50%;
    text-align: center;
    // margin: 37% auto 0 17px;
    margin: 10px auto;
    //  margin-top: 50px;
}
.emailtemplatesbtn {
    height: 38px;
    padding: 10px 20px;
    border: 1px solid #6147fe;
    color: #6147fe;
    border-radius: 30px;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: fixed;
    top: 100px;
    right: 40px;
    z-index: 10;
}
.emailleftheader{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    position: fixed;
    width: 13%;
    // margin-top: 30px;
    text-align: center;
    top:80px;
    height: 100px;
    background:#fff;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    z-index: 2;
    h1{
        margin-bottom: 0px;
    }
}
.emailrightheader{
    position: fixed;
    top:80px;
    // margin-top: 30px;
    background:#fff;
    width: 30%;
    padding: 10px 0px;
    border-bottom: 1px solid lightgray;
    z-index: 2;
}
.rightsidecard{
    // overflow: hidden;
    // overflow-y: auto;
    height: 100%;
    //padding-top: 20px;
    //max-height: 675px;
}
.emailthreadoverflow{
    overflow: hidden;
    overflow-y: auto;
    // height: 100%;
    max-height: 500px;
    padding-bottom: 40px;
}
// .emailmessage{
//     padding-top: 30px;
// }
.emailheading{
    font-size: 12px;
}
.emailaddflex{
    display: flex;
    align-items: center;
    justify-content: center;
}
.emailadd{
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    width: 24px;
    margin-top: 20px;
    height: 24px;
    border-radius: 50%;
    background: #000;
    color: #fff;
    cursor: pointer;
}
.emailsubheading{
    font-size: 10px;
}
.emailtabs {
    display: flex;
    flex-direction: column;
}
.emailtabs a {
    padding: 10px;
    background-color: #E7E3F0FF;
    text-decoration: none;
    color: #333;
    margin-bottom: 20px;
    border: 1px solid #E7E3F0FF;
    border-radius: 5px;
}
.emailtabs a.activeTab {
    background-color: #6147FF;
    color: white;
  }
.emailtabs a:hover {
    background-color: #6147FF;
    color: white;
}
.email-tab-content:target {
    display: block;
}
.emailname{
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    width: 40px;
    height: 35px;
    border-radius: 50%;
    background: rgb(59, 112, 59);
    color: #fff;
}
.emailflexname{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    margin-top: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 600px;
}
.emailflexchattime{
    display: flex;
    align-items: center;
}
.chatbox{
   margin-left: 20px;
   width: 100%;
}
.chattime{
    font-size: 12px;
    margin-left: 40px;
}
.nameperson{
    font-size: 18px;
    font-weight: 600;
    color: #000;
}
.emailcommondetails{
    font-size: 15px;
    // font-weight: 600;
    color: #000; 
    margin-top: 10px;
    margin-bottom: 20px;
}
.emailpersonchatname{
    display: flex;
    flex-wrap: wrap;
    // margin-top: 5px;
    // margin-bottom: 10px;
    img{
        margin: 0px 10px 10px 0px;
    }
}
.emailto{
    margin-right: 10px;
}
.replybtncenter{
    display: flex;
    align-items: center;
    justify-content: center;  
}
.replybtn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    padding: 10px;
    border: 1px solid #6147FEFF;
    border-radius: 30px;
    width: 120px;
    color: #6147FEFF;
    margin-top: 20px;
    cursor: pointer;
}
.refreshimgicon{
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.mainsangamcontainer2 {
    width: 700px;
    border: 1px solid grey;
    margin: 12px auto;
}
.compsoheading {
    padding: 12px 0 0 20px;
}
.threadwidth{
    width: 12%;
}
.threadselectwidth{
    width: 50%;
    select{
        width: 100%;
        height: 38px;
        border: 1px solid rgb(205, 205, 205);
        border-radius: 5px;
    }
}
.allupperinputs {
    margin: 20px 0px;
    display: flex;
    align-items: center;
}
.allupperinputs {
    width: 100%;
}
.allupperinputs input {
    border: 1px solid rgb(205, 205, 205);
    width: 100%;
    height: 38px;
    padding: 10px ;
    margin-right: 9px;
    border-radius: 5px;
}
.emailtextarea{
    display: flex;
}

.allupperinputs3 div{
    /* margin-top: 5px; */
    width: 100%;
    /* height: 50px; */
    display: flex;
    /* flex-wrap: wrap; */
}

.Tofield{
    margin: 12px 0 0 0px;
    // padding-left: 32px;
    width: 12%;
}
.allupperinputs3{
    width: 100%;
    margin: 7px 0 0 0px;
    display: flex;
}
.emailpersoninfo{
    display: flex;
    align-items:center;
    margin: 10px 0px;
    padding: 5px 0px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}
.emailpersoninfo input{
    border: none;
    width: 100%;
    height: 40px;
    margin-left: 10px;
}
/* ------------------------------------------/ */
.addmailinputs{
    width: 100%;
}
.addmailinputs ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px 0;
    border-radius: 10px;
    
}
.addmailinputs ul li {
 margin-bottom: 10px;
 padding: 8px 10px;
 margin: 2px 3px;
 border-radius: 55px;
 border: 1px solid gray;
}
.addmailinputs ul li i{
 display: inline-block;
 width:  20px;
 text-align: center;
 cursor: pointer;
 border-radius: 25px;
 font-size: 12px;
 border: 1px solid rgb(214, 214, 214);
 background-color: #ccc;
}
.addmailinputs input{
    flex: 1;
    border: none;
    font-size: 14px;
    margin-left: 5px;
}

.largtextinconfmail textarea {
    height: 100px;
    width: 100%;
    padding: 10px;
    background-color: #eff7ff;
    resize: none;
    border: 0;
}
.attachfileinmail {
    display: flex;
    align-items: center;
    justify-content: right;
}
.attachfileinmail label {
    display: flex;
    cursor: pointer;
}
.attachfileinmail input {
    display: none;
}
.sendemailbtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 35px 0px 0px 0px;
}
.crossicon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border: 1px solid grey;
    border-radius: 50%; 
    text-align: center; 
    font-weight: bold;
    height: 20px;
    width: 20px; 
    font-size: 12px; 
    cursor: pointer;
}
.sendemailbtn button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding:10px 20px;
    border-radius: 30px;
    cursor: pointer;
    background-color: #4a68fa;
    color: white;
    height: 30px;
    border: 0;
}


.ping {
    width:10px;
    border-radius:50%;
    height:10px;
    background-color:green;
    animation: ping 1s infinite cubic-bezier(1, 0, 0, 1);
}

@keyframes ping {
    75%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }